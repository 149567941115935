import React from 'react';


import './FormHighlights.css'

import icon_api from './../../assets/img/icon-api.png'
import icon_mobile from './../../assets/img/icon-mobile.png'
import icon_interact from './../../assets/img/icon-interact.png'

function FormHighlights() {
    
    return (
        <div className="form-highlights" name="form_highlights">

            <div className="container-fluid">
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10">
                        <h2>Внедряемые визуальные формы<a name="forms">&nbsp;</a></h2>
                    </div>

                    <div className="col-1"></div>
                </div>

                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10">

                        <p className="lead">

                        Этот вариант хорошо подойдет для тех, кому нужно незамедлительно запустить продажи 
                        без участия разработчиков.

                        Все, что вам нужно — это потратить 20 минут времени администратора сайта, чтобы добавить всего три 
                        строчки кода на&nbsp;ваш сайт.
                        
                        </p>

                    </div>
                    <div className="col-1"></div>
                </div>

                <div className="row" >
                    <div className="col-1"></div>
                    <div className="col-10 features" >

                        <div className="row">

                            <div className="col-md-4">
                                <div className="feature">
                                    <div className="feature-icon">
                                        <img src={icon_api} alt="" className="img1" /> 
                                    </div>
                                    <h3>Все включено</h3>
                                    <p className="feature-description">
                                    Формы уже подключены к&nbsp;API. Добавьте их на свой сайт, как виджет, используя всего две строчки кода.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="feature">
                                    <div className="feature-icon">
                                        <img src={icon_mobile} alt="" className="img2"  /> 
                                    </div>
                                    <h3>Дружба с&nbsp;мобильными устройствами</h3>
                                    <p className="feature-description">
                                    Все формы адаптивные и имеют хорошую конверсию, как на рабочих станциях, так и&nbsp;на&nbsp;мобильных устройствах.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="feature">
                                    <div className="feature-icon">
                                        <img src={icon_interact} alt="" className="img3"  /> 
                                    </div>
                                    <h3>Интерактивная песочница</h3>
                                    <p className="feature-description">
                                    Вы можете просмотреть все экраны готовых форм в&nbsp;интерактивной песочнице и&nbsp;протестировать их работу.
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="col-1"></div>
                </div>


                <div className="row" >
                    <div className="col-1"></div>
                    <div className="col-10">

                        <p className="call-to-action">
                            <a href={`${process.env.REACT_APP_WEB_ROOT}/forms/`} className="btn btn-primary cta-button">Посмотреть формы</a>
                        </p>

                    </div>
                    <div className="col-1"></div>
                </div>

            </div>

        </div>
    );
}

export default FormHighlights;
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import NavigationBar from './../../../components/navigation-bar/NavigationBar'
import ApiNavigationBar from './../api-navigation-bar/ApiNavigationBar'

import ApiHeader from './../api-header/ApiHeader'
import ApiEndpoint from './../api-endpoint/ApiEndpoint'
// import ApiMessages from '../api-messages/ApiMessages';
import ConfluenceContent from './../confluence-content/ConfluenceContent'

import {
    getApiStructureAction,
    getApiDictionaryAction
} from '../../../store/actions/apiActions'

import './ApiDictionary.css'


// function renderAttachments(dict, apiId, id) {
//     return (
//         <React.Fragment>
//             <div className="confluence-content">
//                 <div className="row">
//                         <div className="col-1"></div>
//                         <div className="col-10">
//                             {dict.attachments.map( item => {
//                                 return <React.Fragment>
//                                     <h2>{item.name}</h2>
//                                     <p>
//                                         <a href={`${process.env.REACT_APP_WEB_ROOT}/storage/${apiId}/${id}/attachments/${item.filename}`} target="_blank">{item.filename}</a>
//                                     </p>
//                                 </React.Fragment>
//                             })}


//                         </div>
//                         <div className="col-1"></div>
//                 </div>
//             </div>
//         </React.Fragment>
//     );
// }

function renderDictionary(dict, api, id) {
    return (
        <div key={dict.name} className="dict-item">

            <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                <hr/>
                    <h3>{dict.name}</h3>
                    <p className="dict-description">{dict.description}</p>
                </div>
                <div className="col-1"></div>
            </div>
            <ApiEndpoint api={api} dictId={id} endpoint={dict.endpoint} />

        </div>





   );
}

function ApiDictionary({
    api,
    getApi,
    content,
    getContent,
    match
}) {

    const apiId = match.params.apiId;
    const id = `${match.params.dictPart}.DICT.${match.params.apiPart}`;

    // console.error(id);

    useEffect(() => {
        getApi(apiId, (api) => {
            if (api) getContent(id, api);
        });
    }, [id]);


const [htmlContent, setHtmlContent] = React.useState('');

    React.useEffect(() => {

        let result = (content.html || '');

        let pps1 = result.indexOf('<h2 ');
        let pps2 = result.indexOf('ProxyEndpoint</h2>');

        //console.log('xxxx2',pps1, pps2, result)

        if (pps1 >= 0 && pps2 > 0) {
            let headerPos = result.indexOf('<h2 ', pps1+1);
            // result = `${result.substring(
            //   //pps2 + 'ProxyEndpoint</h2>'.length,
            //   pps1,
            //   headerPos
            // )}`;
            // console.log('result', result);
            //abc
            result = result.substring(headerPos)
        }

        setHtmlContent(result);

    }, [content?.html]);

    if (api === null || api === undefined || content === null || content === undefined) return null;

    const dict = api.getDictionaryById(id);

    let dictInfo = api.clearName(dict.name);



    // console.error(content.endpoint);

    return (
      <React.Fragment>
        <NavigationBar />
        <div className="api-dictionary">
          <div className="container-fluid">
            <ApiHeader name={api.name} />
            <ApiNavigationBar api={api} />

            <div className="api-dictionary-content">
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <h2>
                    {dictInfo.link.dict} - {dictInfo.name}
                  </h2>
                </div>
                <div className="col-1"></div>
              </div>

              <ConfluenceContent content={htmlContent} />

              {content.dictionaries &&
                content.dictionaries.map((item) =>
                  renderDictionary(item, api, id)
                )}

              {/* <ApiEndpoint api={api} dictId={id} endpoint={content.endpoint} />
                        {dict.attachments && dict.attachments.length > 0 ? renderAttachments(dict, apiId, id): null} */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}


const mapStateToProps = (state, ownProps) => {
    return {
        api: state.api.structure,
        content: state.api ? state.api.dictionary : ''
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getApi: (id, callback) => {
            dispatch(getApiStructureAction(id, callback));
        },
        getContent: (id, api, callback) => {
            dispatch(getApiDictionaryAction(id, api, callback));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApiDictionary));

import React, { useEffect, useState } from 'react';

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import {ENDPOINT_PREFIX} from './../../../services/contentService/constants'

import ApiTry from './../api-try/ApiTry'

import {
    //getApiStructureAction,
    getApiSampleAction,
    clearApiSampleAction
} from '../../../store/actions/apiActions'

import {
    Form, InputGroup,
    
} from 'react-bootstrap';

import './ApiInvoke.css'



// function getEndpointReadOnlyPart(endpoint) {
    
//     if (!endpoint) return '';
//     let parts = endpoint.split('/');
//     let part = parts[0] || parts[1];

   
//     if (part && part.indexOf('-snd')) {
//         return ENDPOINT_PREFIX + part + '/';
//     }
//     return '';
// }


function ApiInvoke({
    embeded,
    initValue,
    operationId,
    api,
    sample,
    getSample,
    clearSample,
    history,
    match
}) {

    // const [response, setResponse] = useState(null);
    const [savedEndpoint, setSavedEndpoint] = useState('');
    //const [activeTab, setActiveTab] = useState('request');
    const [selectedSampleId, setSelectedSampleId] = useState("none");

    useEffect(() => {
        
        return () => {
            //setSelectedSampleId('none');
            clearSample();
            
        }
    }, []);

    if (api === null || api === undefined) return null;

    function selectSample(e) {
        let id = e.target.value;

        if (id) {            
            getSample(id, api, (payload) => {
                //console.error(payload)
                setSelectedSampleId(payload.id);
            })
        }
    }

    //console.error('os', operationId, selectedSampleId)

    const endpoint = savedEndpoint || (sample.data ? sample.data.sandboxEndpoint: '');
   //console.log('sample',sample) 
   
    let operation = null;
    
    if (operationId) operation = api.getOperationById(operationId);

    if (!operation) return null;

    return (
        <React.Fragment>
             
            <div className="api-invoke">
                <div className="row">
                    <div className={embeded ? "" : "col-1"}></div>
                    <div className={embeded ? "col-12" : "col-10"}>
                        <Form>
                            <Form.Group>
                                <Form.Label>Пример запроса</Form.Label>
                                <Form.Control as="select" onChange={selectSample}
                                    value={selectedSampleId}>
                                    
                                    <option key="none" value="none">Выберите пример запроса</option>
                                    {operation.samples.map(item => {
                                        return <option key={item.id} value={item.id}>{item.name}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                                   
                            {selectedSampleId!=="none" && sample && sample.data  && endpoint ? <Form.Group>
                                <Form.Label>Endpoint</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>{ENDPOINT_PREFIX + sample.data.readOnlyEndpoint}</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={endpoint}
                                        onChange={(e) => setSavedEndpoint(e.target.value)}
                                    />
                                </InputGroup>
                            </Form.Group>: null}
                        </Form>

                        

                        {sample.id ? <ApiTry embeded={embeded} message={sample.data.message}
                         readOnlyEndpoint={ENDPOINT_PREFIX + sample.data.readOnlyEndpoint}
                         sandboxEndpoint={sample.data.sandboxEndpoint}
                          method={sample.data.method}
                           userEndpointPart={endpoint} /> : null}

                    </div>
                    <div className={embeded ? "" : "col-1"}></div>
                </div>
            </div>

        </React.Fragment>
    )
}


const mapStateToProps = (state, ownProps) => {
    return {
        sample: state.api.sample
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSample: (id, api, callback) => {
            dispatch(getApiSampleAction(id, api, callback));
        },
        clearSample: (id, api, callback) => {
            dispatch(clearApiSampleAction());
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApiInvoke));
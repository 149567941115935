import React from 'react';

import './FormListItem.css'
import formImage from './../../../assets/img/hero.png'

function FormListItem({ id, title }) {
    let url = `${process.env.REACT_APP_WEB_ROOT}/api/${id}/forms`;
    return (
        <div className="form-list-item">
            <div className="row product-icon" style={
                    {
                        // background: `url(${process.env.REACT_APP_SERVER_ROOT}${formImage})`
                    }
                }>
                <div className="col">
                    <a href={url}>
                    <img alt={title}
                    src={formImage}
                    className="img-fluid product-image" />
                    </a>
                </div>
            </div>

            <div className="row product-description">
                <div className="col">
                    <h3><a href={url}>{title}</a></h3>
                </div>
            </div>

            {/* <div className="row product-footer ">
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <hr className="product-divider" />
                        </div>
                    </div>

                    <div className="row no-gutters">
                        <div className="col-8">
                        <div className="product-cta">
                                <a className="product-cta-text" href={url}>Посмотреть формы</a>
                            </div>
                        </div>
                       
                    </div>
                    
                   
                </div>
            </div> */}

        </div>
    );
}

export default FormListItem
import React, { useEffect, useRef, useState } from 'react';

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import NavigationBar from './../../../components/navigation-bar/NavigationBar'
import ApiNavigationBar from './../api-navigation-bar/ApiNavigationBar'
import ApiHeader from './../api-header/ApiHeader'


import {
    getApiStructureAction
} from '../../../store/actions/apiActions'

import './ApiSandbox.css'
import ApiSandboxForm from '../api-sandbox-form/ApiSandboxForm';


function ApiSandbox({
    api,
    getApi,
    match
}) {

    const apiId = match.params.apiId;


    useEffect(() => {
        getApi(apiId, (api) => {

        });
    }, [apiId]);

    if (api === null || api === undefined) return null;


    return (
        <React.Fragment>
            <NavigationBar />
            <div className="api-sandbox">
                <div className="container-fluid">
                    <ApiHeader name={api.name} />
                    <ApiNavigationBar api={api} />
                    <br/>
                    <ApiSandboxForm />
                </div>
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = (state, ownProps) => {
    return {
        api: state.api.structure
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getApi: (id, callback) => {
            dispatch(getApiStructureAction(id, callback));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApiSandbox));
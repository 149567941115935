import {
    PRODUCTS_GET_STRUCTURE_FULFILLED,
    FORMS_GET_STRUCTURE_FULFILLED,
    API_GET_STRUCTURE_FULFILLED,
    API_GET_DESCRIPTION_FULFILLED,
    API_GET_BUC_FULFILLED,
    API_GET_OPERATION_FULFILLED,
    API_GET_OPERATION_MESSAGE_FULFILLED,
    API_GET_DICTIONARY_FULFILLED,
    API_GET_PROCEDURE_FULFILLED,
    API_CLEAR_SAMPLE_FULFILLED,
    API_GET_SAMPLE_FULFILLED,
    API_GET_STANDARD_FULFILLED
 } from './types'
import ContentService from '../../services/contentService/index'

const CONTENT_FILE_NAME = "/description.html"

export function getProductsStructureAction (callback) {
    return (dispatch) => {
        return ContentService.getProductsStructure().then(data => {
            dispatch({
                type: PRODUCTS_GET_STRUCTURE_FULFILLED,
                payload: data
            });
            if (typeof callback === 'function') callback(data);
        })
    }   
}

export function getFormsStructureAction (callback) {
    return (dispatch) => {
        return ContentService.getFormsStructure ().then(data => {
            dispatch({
                type: FORMS_GET_STRUCTURE_FULFILLED,
                payload: data
            });
            if (typeof callback === 'function') callback(data);
        })
    }   
}

export function getApiStructureAction (apiId, callback) {
    return (dispatch) => {
        return ContentService.getStructure(apiId).then(data => {
            dispatch({
                type: API_GET_STRUCTURE_FULFILLED,
                payload: data
            });
            if (typeof callback === 'function') callback(data);
        })
    }   
}

export function getApiDescriptionAction (api, callback) {
    return (dispatch) => {
        return ContentService.getDescription(api.id, CONTENT_FILE_NAME).then(data => {
            dispatch({
                type: API_GET_DESCRIPTION_FULFILLED,
                payload: data
            });
            if (typeof callback === 'function') callback(data);
        })
    }
}

export function getApiBucAction (bucId, api, callback) {
    return (dispatch) => {
        return ContentService.getBuc(bucId, api.id, CONTENT_FILE_NAME).then(data => {
            dispatch({
                type: API_GET_BUC_FULFILLED,
                payload: data
            });
            if (typeof callback === 'function') callback(data);
        })
    }
}

export function getApiStandardAction (standardId, api, callback) {
    return (dispatch) => {
        return ContentService.getStandard(standardId, api.id, CONTENT_FILE_NAME).then(data => {
            dispatch({
                type: API_GET_STANDARD_FULFILLED,
                payload: data
            });
            if (typeof callback === 'function') callback(data);
        })
    }
}

export function getApiOperationAction (operId, api, callback) {
    return (dispatch) => {
        return ContentService.getOperation(operId, api.id, CONTENT_FILE_NAME).then(data => {
            dispatch({
                type: API_GET_OPERATION_FULFILLED,
                payload: data
            });
            if (typeof callback === 'function') callback(data);
        })
    }
}

export function getApiOperationMessageAction (messageId, api, callback) {
    return (dispatch) => {
        return ContentService.getOperationMessage(messageId, api.id, CONTENT_FILE_NAME).then(data => {
            dispatch({
                type: API_GET_OPERATION_MESSAGE_FULFILLED,
                payload: data
            });
            if (typeof callback === 'function') callback(data);
        })
    }
}

export function getApiDictionaryAction (dictId, api, callback) {
    return (dispatch) => {
        return ContentService.getDictionary(dictId, api.id, CONTENT_FILE_NAME).then(data => {
           
            dispatch({
                type: API_GET_DICTIONARY_FULFILLED,
                payload: data
            });
            if (typeof callback === 'function') callback(data);
        })
    }
}

export function getApiProcedureAction (procId, api, callback) {
    return (dispatch) => {
        return ContentService.getProcedure(procId, api.id, CONTENT_FILE_NAME).then(data => {
            dispatch({
                type: API_GET_PROCEDURE_FULFILLED,
                payload: data
            });
            if (typeof callback === 'function') callback(data);
        })
    }
}

export function getApiSampleAction (sampleId, api, callback) {
    return (dispatch) => {
        return ContentService.getSample(sampleId, api.id, CONTENT_FILE_NAME).then(data => {
            let result = {
                id: sampleId,
                data: data
            }
            dispatch({
                type: API_GET_SAMPLE_FULFILLED,
                payload: result
            });
            if (typeof callback === 'function') callback(result);
        })
    }
}

export function clearApiSampleAction () {
    return (dispatch) => {
        return dispatch({
            type: API_CLEAR_SAMPLE_FULFILLED
        });
    }
}





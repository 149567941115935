import React, { useEffect, useRef, useState } from 'react';

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {ENDPOINT_PREFIX} from './../../../services/contentService/constants'

import ApiTry from './../api-try/ApiTry'

import ContentService from './../../../services/contentService'
import ConfluenceContent from './../confluence-content/ConfluenceContent'

import ApiInvoke from '../api-invoke/ApiInvoke';

import {
    Form
} from 'react-bootstrap';

import {
    getApiStructureAction
} from '../../../store/actions/apiActions'

import './ApiSandboxForm.css'


function ApiSandboxForm({
    openSandbox,
    currentOperation,
    embeded,
    api,
    getApi,
    match
}) {


    const apiId = match.params.apiId;

    const viewPortWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    
    const [samples, setSamples] = useState([]);
    //const [opened, setOpened] = useLocalStorage('sandbox-window', (embeded ? 'false': 'true') );
    const [opened, setOpened] = useLocalStorage('sandbox-window', (embeded ? 'false': 'true') );
    
    

    useEffect(() => {
        if (!embeded) setOpened('true');
        getApi(apiId, (api) => {
            downloadSamples();
            //let status = (opened === 'true') ? true : !(opened === 'na' && availableOperationsCount > 0);

            if (openSandbox) {
                openSandbox((opened === 'true'));
                
            }
        });
        
        //if (opened !== undefined) setOpened(localStorage.setItem("sandbox-window", opened));        
                
    }, [apiId, embeded, opened, match.params]);

    if (api === null || api === undefined) return null;

    let operations = api.operations.filter(item => item.samples && item.samples.length > 0);
    let availableOperationsCount = 0;

    if (currentOperation) {
        availableOperationsCount = operations.filter(operation => operation.id === match.params.operId).length;
    }else {
        availableOperationsCount = operations.length;
    }
    
    

    


    function handleClose() {

        if (opened === 'true') {
            setOpened('false');
        }else if (opened === 'false') {
            setOpened('true');
        }
        // if (opened === 'true' || (availableOperationsCount == 0)) setOpened('false');
        // if (opened === 'false' || (availableOperationsCount > 0)) setOpened('true');                
    }    

    function renderCloseButton(embeded, handleClose, opened) {
        if (!embeded) return null;
        //console.log(opened === true);
        
        
        return <>
        {opened === 'true' && <div className="close-button" onClick={handleClose}>свернуть &nbsp;<span className="icon-button">→</span></div>}        
        </>
    }

    function downloadSamples() {
        const samples = [];
        let promises = [];

        let filteredOperations = operations;

        if (embeded && currentOperation) {
            filteredOperations = operations.filter(operation => operation.id === match.params.operId);
        }        
        
        filteredOperations.forEach(operation => {            
            operation.samples.forEach(sample => {                
                let promise = ContentService.getSample(sample.id, api.id, "/description.html").then(data => {
                    let item = {
                        description: data.description,
                        name: sample.name,
                        operation: operation,
                        message: data.message,
                        readOnlyEndpoint:  data.readOnlyEndpoint,
                        method: data.method,
                        userEndpointPart: data.sandboxEndpoint || ''
                    };
                    
                    samples.push(item);
                });
                promises.push(promise);
            });
        });

        Promise.all(promises).then(()=> {
            //console.log(samples)

            samples.sort(function (a, b) {
                return ('' + a.operation.bucId).localeCompare(b.operation.bucId);
            })

            setSamples(samples);            
        })
    }

    function renderOperations() {
        //if (!samples.length) return null;
                
        if (embeded && availableOperationsCount === 0) {
            let name = api.operations.filter(operation => operation.id === match.params.operId)[0].name
            return <>
            <p>К сожалению, для операции <b>"{name}"</b> пока не добавлено ни одного примера.</p>
            <p>
            <a href={`${process.env.REACT_APP_WEB_ROOT}/api/${match.params.apiId}/sandbox`}>Перейти в песочницу</a>
            </p>
            
            <p>
                <span style={{color: '#1990fe', cursor: 'pointer'}} onClick={handleClose}>Закрыть</span>
            </p>
            

            </>
        }

        return samples.map(sample => {
                    
                return <div key={sample.name}>
                    <h3>{sample.operation.name}</h3>
                    <h4>{sample.name}</h4>
                    <br/>
                    {/* {console.log(sample)} */}
                    <ConfluenceContent noColumns={true} content={sample.description} />
                        <ApiTry name={sample.name} embeded={embeded}  message={sample.message}
                         readOnlyEndpoint={ENDPOINT_PREFIX + sample.readOnlyEndpoint}
                          method={sample.method}
                           userEndpointPart={sample.userEndpointPart} />
                           <br/>
                           <br/>
                           
                           <br/>
                           <br/>
                    </div>
        })
           
    }

    let canShow = (opened === 'true');

    if (!embeded) canShow = true;

    return (
        <React.Fragment>
            {opened === 'false' && <div className="close-button closed" onClick={handleClose}><span className="icon-button">←</span></div>}
            <div className="api-sandbox-content" style={opened === 'false' ? {display: 'none'} : undefined}>
                <div className="row">
                    <div className={embeded ? "" : "col-1"}></div>
                    <div className={embeded ? "col-12" : "col-10"}>
                        {renderCloseButton(embeded, handleClose, opened)}                        
                        {canShow && <>
                        <br/><br/>
                        {!embeded && <h2>Попробовать API в песочнице</h2>}
                        {embeded && <h2>Примеры запросов по операции</h2>}
                            
                            { (!embeded) && <p>Это специальный режим работы Open API сервисов для отладки ваших приложений, который полностью изолирован от настоящих данных. Используйте подготовленные ниже примеры сообщений, чтобы отправлять сообщения в API и получать ответы. </p>}
                        </>}

                        
                    </div>
                    <div className={embeded ? "" : "col-1"}></div>
                </div>

                <div className="row">
                    <div className={embeded ? "" : "col-1"}></div>
                    <div className={embeded ? "col-12" : "col-10"}>
                        {canShow && <>
                            {renderOperations()}                            
                        </>}
                        
                    </div>
                    <div className={embeded ? "" : "col-1"}></div>
                </div>

                {/* {canShow && selectedOperationId && selectedOperationId !== "none" && <ApiInvoke embeded={embeded} operationId={selectedOperationId} api={api} />} */}

            </div>



        </React.Fragment>
    )
}


const mapStateToProps = (state, ownProps) => {
    return {
        api: state.api.structure
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getApi: (id, callback) => {
            dispatch(getApiStructureAction(id, callback));
        }
    }
}

function useLocalStorage(key, initialValue) {    
    const [storedValue, setStoredValue] = useState(() => {
      try {
        const item = window.localStorage.getItem(key);        
        if (item === undefined || item === null) return initialValue;        
        return item;
      } catch (error) {               
        return initialValue;
      }
    });
      
    const setValue = value => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        window.localStorage.setItem(key, valueToStore);
      } catch (error) {
        
      }
    };
  
    return [storedValue, setValue];
  }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApiSandboxForm));
import React from 'react';
import NavigationBar from './../../components/navigation-bar/NavigationBar'

import FormList from './../../components/form-list/FormList'


import './FormsView.css'


function FormsView() {
    return (
        <React.Fragment>
        
                <NavigationBar />
                <FormList />
            
        </React.Fragment>
    )
}

export default FormsView;
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
// import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux';

import {
  // API_DESCRIPTION_PATH,
  // API_BUC_PATH,
  // API_OPERATION_PATH,
  // API_DICTIONARY_PATH,
  // API_PROCEDURE_PATH,
  // API_SANDBOX_PATH,
  API_FORMS_LIST_PATH,
  // API_FORMS_PATH,
  // API_FORM_PREVIEW_PATH
} from './../../routes/index';

import {
  getProductsStructureAction,
  getFormsStructureAction,
} from '../../store/actions/apiActions';

import './NavigationBar.css';
import logo from './../../assets/img/logo.png';

import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

function NavigationBar({ products, forms, getProducts, getForms, match }) {
  // const [showRegistration, setShowRegistration] = useState(true);

  useEffect(() => {
    getProducts();
    getForms();
  }, []);

  if (
    products === null ||
    products === undefined ||
    forms === null ||
    forms === undefined
  )
    return null;

  const showRegistrationModal = () => {
    let element = window.document.getElementById('form-modal-box');
    element.classList.add('show');
  };

  let uniqueForms = [];
  let uForms = {};

  forms.map((item) => {
    if (!uForms[item.apiTitle]) {
      uForms[item.apiTitle] = true;
      uniqueForms.push(item);
    }
    return undefined;
  });

  // var apisWithForms = [];

  // for (let i=0; i< products.length; i++) {
  //     let apis = products[i].APIS;

  //     let found = apis.filter(item => forms.map((f) => f.apiId).indexOf(item.id) >=0 );

  //     if (found.length > 0) {
  //         apisWithForms = [...apisWithForms, found[0]];
  //     }
  // }

  function handleGoToContacts(e) {
    e.preventDefault();
    let contactElement = document.querySelector('.contact-light-highlights');
    if (contactElement) {
      contactElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

  return (
    <>
      <div className="navigation-bar">
        <div className="container-fluid">
          <div className="row navigation-bar--row">
            <div className="col-1"></div>
            <div className="col navigation-bar-logo">
              <a href={`${process.env.REACT_APP_WEB_ROOT}/`}>
                <img alt="" id="raiffeisen-logo" src={logo}></img>
              </a>
            </div>

            <div className="col-sm-auto">
              <ul className="navigation-bar_list">
                {/* <li>
                                <a href={`${process.env.REACT_APP_WEB_ROOT}/products/`}>Войти</a>
                            </li> */}
                {/* <li>
                    <button
                      onClick={showRegistrationModal}
                      className="btn btn-primary"
                    >
                      Оставить заявку на&nbsp;подключение
                    </button>
                  </li> */}
              </ul>
            </div>

            <div className="col-1"></div>
          </div>

          <div
            className="row navigation-bar--row"
            style={{ marginLeft: '10px' }}
          >
            <div className="col-1"></div>

            <div className="col-sm-auto">
              <Navbar collapseOnSelect expand="md">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="mr-auto navigation-bar_items">
                    <Nav.Link
                      href={`${process.env.REACT_APP_WEB_ROOT}/#get-started`}
                    >
                      Как&nbsp;подключиться
                    </Nav.Link>

                    <NavDropdown
                      title="API продукты"
                      id="collasible-nav-dropdown"
                    >
                      {products.map((item) => {
                        return (
                          <NavDropdown.Item
                            key={item.id}
                            href={`${process.env.REACT_APP_WEB_ROOT}/products/${item.id}`}
                          >
                            {item.name}
                          </NavDropdown.Item>
                        );
                      })}
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        href={`${process.env.REACT_APP_WEB_ROOT}/products/`}
                      >
                        Все API продукты
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="GUI формы">
                      {uniqueForms.map((form) => {
                        return (
                          <NavDropdown.Item
                            key={form.id}
                            active={false}
                            href={`${process.env.REACT_APP_WEB_ROOT}/api/${form.apiId}/forms/`}
                          >
                            {form.apiTitle}
                          </NavDropdown.Item>
                        );
                      })}

                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        active={match.path === API_FORMS_LIST_PATH}
                        href={`${process.env.REACT_APP_WEB_ROOT}/forms/`}
                      >
                        Все формы
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                      title="Помощь разработчику"
                      id="collasible-nav-dropdown2"
                    >
                      <NavDropdown.Item
                        active={false}
                        href={`${process.env.REACT_APP_WEB_ROOT}/#getting-started`}
                      >
                        Как получить сертификат
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link
                      href={`${process.env.REACT_APP_WEB_ROOT}/#contacts`}
                      onClick={handleGoToContacts}
                    >
                      Контакты
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>

            <div className="col-1"></div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    products: state.api.products,
    forms: state.api.forms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: (id, callback) => {
      dispatch(getProductsStructureAction(id, callback));
    },
    getForms: (id, callback) => {
      dispatch(getFormsStructureAction(id, callback));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavigationBar)
);

import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import './ApiForms.css'

import ApiFormInstance from './api-form-instance/ApiFormInstance'

import NavigationBar from './../../../components/navigation-bar/NavigationBar'
import ApiNavigationBar from './../api-navigation-bar/ApiNavigationBar'

import ApiHeader from './../api-header/ApiHeader'

import {
    getApiStructureAction,
    getFormsStructureAction,
    getProductsStructureAction,

} from '../../../store/actions/apiActions'


function ApiForms({
    api,
    getApi,
    forms,
    getForms,
    products,
    getProducts,
    match,
    history
}) {

    const apiId = match.params.apiId;

    useEffect(() => {
        getApi(apiId);
        getProducts();
        getForms();
    }, [apiId]);

    if (api === null || api === undefined || products === null || products === undefined || forms === null || forms === undefined) return null;


    var currentForms = forms.filter(item => item.apiId === apiId);
    //console.error(api)


    return (

        <React.Fragment>
            <NavigationBar />
            <div className="api-buc">
                <div className="container-fluid">
                    <ApiHeader name={api.name} />
                    <ApiNavigationBar api={api} />

                    <div className="api-buc-content">
                        <div className="row">
                            <div className="col-1"></div>
                            <div className="col-10">
                                <h2>Визуальные формы {apiId}</h2>
                            </div>
                            <div className="col-1"></div>
                        </div>
                    </div>


                    <div className="api-forms">

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-1"></div>
                                <div className="col-10">

                                    <div className="row">

                                        {currentForms.map((form) => {

                                            return <div key={form.id} className="col-lg-4">
                                                <ApiFormInstance id={form.id}
                                                    apiId={form.apiId}
                                                    title={form.title}
                                                />
                                            </div>
                                        })}

                                    </div>
                                </div>
                                <div className="col-1"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>



    )
}
const mapStateToProps = (state, ownProps) => {
    return {
        api: state.api.structure,
        forms: state.api.forms,
        products: state.api.products,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getApi: (id, callback) => {
            dispatch(getApiStructureAction(id, callback));
        },
        getForms: (id, callback) => {
            dispatch(getFormsStructureAction(id, callback));
        },
        getProducts: (id, callback) => {
            dispatch(getProductsStructureAction(callback));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApiForms));




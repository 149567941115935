import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import './FormList.css'

import FormListItem from './form-list-item/FormListItem'

import {
    getFormsStructureAction,
    getProductsStructureAction,
    
} from '../../store/actions/apiActions'





function FormList({
    forms,
    getForms,
    products,
    getProducts,
    history
}) {

    const element = useRef(null);

    useEffect(() => {
        if (element && element.current && window.location.href.indexOf("forms") >= 0) {
            element.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            // var parts = window.location.href.split('#');
            // if (parts.length >= 2) {
            //     window.location = [parts[0], parts[1]].join('#');
            // }
        }

        if (!products) getProducts();
        if (!forms) getForms();
    }, []);

    if (products === null || products === undefined || forms === null || forms === undefined) return null;

    let uniqueForms = [];
    let uForms = {};

    forms.map(item => {
        if (!uForms[item.apiTitle]) {
            uForms[item.apiTitle] = true;
            uniqueForms.push(item);
        }
        return undefined;
    });

    

    // console.error(apisWithForms);
    // return <div/>;

    // const scrollToForms = (e) => {
    //     history.push('/forms');
    //     // window.scroll({
    //     //     behavior: 'smooth',
    //     //     left: 0,
    //     //     top: window.document.getElementsByName('form_highlights')[0].offsetTop
    //     //   });

    //       e.preventDefault();
    // }


    return (
        <div className="form-list" ref={element}>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10">
                        <h2>Все визуальные формы, подключенные к API <a name="forms" href="">&nbsp;</a></h2>
                    </div>

                    <div className="col-1"></div>
                </div>

                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10">

                        <p className="lead">
                        Визуальные формы хорошо подойдут тем, кому нужно незамедлительно запустить продажи 
                        без участия разработчиков.

                        Все, что вам нужно — это потратить 20 минут времени администратора сайта, чтобы добавить всего три 
                        строчки кода на&nbsp;ваш сайт.
                        </p>
                        

                    </div>
                    <div className="col-1"></div>
                </div>


                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10">

                        <div className="row">

                        {uniqueForms.map( (form) => {
                            
                            return <div key={form.id} className="col-lg-4">
                                <FormListItem  id={form.apiId}
                                title={form.apiTitle}
                                />
                            </div>
                        })}   

                        </div>

                    </div>
                    <div className="col-1"></div>
                </div>

            </div>

        </div>

    )
}
const mapStateToProps = (state, ownProps) => {
    return {
        forms: state.api.forms,
        products: state.api.products,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getForms: (id, callback) => {
            dispatch(getFormsStructureAction(id, callback));
        },
        getProducts: (id, callback) => {
            dispatch(getProductsStructureAction(callback));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormList));




import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'


import './ApiProductHighlights.css'

import ApiProductHighlightItem from './api-product-highlight-item/ApiProductHighlightItem'

import {
    getProductsStructureAction,

} from '../../store/actions/apiActions'



function ApiProductHighlights({
    products,
    getProducts,
    history
}) {

//console.log('products', products)

    useEffect(() => {
        getProducts();
    }, []);

    if (products === null || products === undefined) return null;

    // const scrollToForms = (e) => {
    //     history.push('/forms');
    //     // window.scroll({
    //     //     behavior: 'smooth',
    //     //     left: 0,
    //     //     top: window.document.getElementsByName('form_highlights')[0].offsetTop
    //     //   });

    //       e.preventDefault();
    // }


    return (
        <div className="api-product-highlights">

            <div className="container-fluid">
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10">
                        <h2>API продукты Райффайзенбанка <a name="api-products" href="">&nbsp;</a></h2>
                    </div>

                    <div className="col-1"></div>
                </div>

                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10">

                        <p className="lead">
                        Монетизируйте трафик своих контентных веб-проектов или мобильных приложений, используя
                        API Райффайзенбанка.
                        Здесь вы найдете подробную документацию по каждому API-сервису, включающую в себя описание
                        сценариев использования и&nbsp;интерактивную песочницу.
                        </p>
                        <p className="lead">Не готовы использовать API-сервисы? Тогда воспользуйтесь нашими визуальными формами для размещения их на вашем сайте. </p>

                        {/* <p className="lead">Не готовы использовать API-сервисы? Тогда воспользуйтесь нашими <a href={`${process.env.REACT_APP_WEB_ROOT}/#forms`}>визуальными формами</a> для размещения их на вашем сайте. </p> */}

                    </div>
                    <div className="col-1"></div>
                </div>


                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10">

                        <div className="row">

                        {products.map( (product) => {
                            return <div key={product.id} className="col-lg-4">
                                <ApiProductHighlightItem  id={product.id}
                                title={product.name}
                                apiCount={product.APIS.length}
                                icon={product.icon}
                                description={product.description} />
                            </div>
                        })}

                        </div>

                    </div>
                    <div className="col-1"></div>
                </div>

            </div>

        </div>

    )
}
const mapStateToProps = (state, ownProps) => {
    return {
        products: state.api.products
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getProducts: (id, callback) => {
            dispatch(getProductsStructureAction(id, callback));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApiProductHighlights));

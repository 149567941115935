
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import ConfluenceContent from './../confluence-content/ConfluenceContent'

import {
    getApiOperationMessageAction
} from '../../../store/actions/apiActions'

import {
    Badge
} from 'react-bootstrap';
import './ApiMessages.css'

function getBadgeName(type, method) {

    if (type === 'request') {
            return `${method} запрос`
    }else if (type === 'error') {
        return `Сообщение об ошибке`
    }
    else {
        return 'Успешный ответ'
    }

}

function ApiMessages({ api, operation, content, getContent, match, history }) {

    const [message] = useState(match?.params?.messageId ? api.getMessageById(match.params.messageId): undefined);

    

    

    const method = operation.id.split('.')[0];
    // if (!messageId) messageId = operation.messages[0].id;

    // const [currentMessageId, setCurrentMessageId] = useState(messageId);

    //console.error(history);

    useEffect(() => {
        
        if (message) {
            getContent(message.id, api, (data) => {
                document.getElementById('messageHeader').scrollIntoView(
                    {behavior: "smooth", block: "start", inline: "nearest"}
                    );
            });
            
        }
    }, [message]);
    
    if (api === null || api === undefined) return null;
    if (operation === null || operation === undefined) return null;
    
    function setCurrentMessageId(e, messageId) {
        //console.error(messageId, history);
        //setMessage(api.getMessageById(operation, messageId));
        history.push(``);
    }


    return (
        <React.Fragment>
            <div className="api-messages">
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10">
                        <h4>Описание сообщений</h4>
                    </div>
                    <div className="col-1"></div>
                </div>

                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10">
                        <div className="execution">
                            <ul className="execution-list">
                                {operation.messages.map(item => {

                                    if (message && item.id === message.id) {
                                        return (<li key={item.id} className="active">{item.name}
                                        </li>);
                                    } else {
                                        return (
                                            <li key={item.id}><a href={`${process.env.REACT_APP_WEB_ROOT}/api/${operation.apiId}/${operation.bucId}/${operation.id}/${item.id}`} onClick={(e) => setCurrentMessageId(e, `${item.id}`) }>{item.name}</a>
                                            </li>
                                        )
                                    }
                                })}
                            </ul>

                        </div>
                    </div>
                    <div className="col-1"></div>
                </div>
                {!message || !content ? null : (
                    <React.Fragment>
                        <div className="row">
                            <div className="col-1"></div>
                            <div className="col-10">
                                <h2 id="messageHeader">{message.name}</h2>
                                <hr />
                            </div>
                            <div className="col-1"></div>
                        </div>

                        <ConfluenceContent content={content} />
                    </React.Fragment>)}


            </div>
        </React.Fragment>

    );
}


const mapStateToProps = (state, ownProps) => {
    return {
        content: state.api ? state.api.operationMessage : ''
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getContent: (id, api, callback) => {
            dispatch(getApiOperationMessageAction(id, api, callback));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApiMessages));
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'



import NavigationBar from './../../../components/navigation-bar/NavigationBar'
import ApiNavigationBar from './../api-navigation-bar/ApiNavigationBar'

import ApiHeader from './../api-header/ApiHeader'
import ConfluenceContent from './../confluence-content/ConfluenceContent'
import ApiSandboxForm from "./../api-sandbox-form/ApiSandboxForm";

import {
    getApiStructureAction,
    getApiStandardAction
} from '../../../store/actions/apiActions'

import './ApiStandard.css'



function ApiStandard({
    api,
    getApi,
    content,
    getContent,
    match
}) {

    // "/api/:apiId/CHECK:checkNum.BUC:bucId.:apiPart"
    const apiId = match.params.apiId;
    let id = `STD${match.params.stdNum}.${match.params.stdPostfix}.${match.params.apiPart}`;

    //const [sandboxOpened, setSandboxOpened] = useState(localStorage.getItem("sandbox-window") || false );
    const [sandboxOpened, setSandboxOpened] = useState(false );
    
    function openSandbox(opened) {    
        setSandboxOpened(opened);    
      }

    useEffect(() => {
        getApi(apiId, (api) => {
            if (api) getContent(id, api);
        });
    }, [id]);

    if (api === null || api === undefined || content === null || content === undefined) return null;

    const proc = api.getStandardById(id);

    //let procInfo = api.clearName(proc.name);

    // console.error(content.endpoint);

    return (
        <React.Fragment>
            <NavigationBar />
            <div className="api-standard">
                <div className="container-fluid">
                    <ApiHeader name={api.name} />
                    <ApiNavigationBar api={api} />

                    <div className="api-standard-content">
                        <div className="row">
                            <div className="col-1"></div>
                            <div className="col-10">
                                <h2>{proc.name}</h2>
                            </div>
                            <div className="col-1"></div>
                        </div>

                        
                        <div className={ (proc.name.toLowerCase().indexOf('генерация') > 0 ? "table-compact": "") }>
                        <ConfluenceContent content={content} />
                        </div>
                        

                        

                    </div>



                </div>
            </div>
            {/* <div className={sandboxOpened? "sandbox-modal" : "sandbox-modal pin" }  >
                <ApiSandboxForm currentOperation={false} embeded={true} openSandbox={openSandbox} />
            </div> */}
        </React.Fragment>
    )
}


const mapStateToProps = (state, ownProps) => {
    return {
        api: state.api.structure,
        content: state.api ? state.api.standard : ''
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getApi: (id, callback) => {
            dispatch(getApiStructureAction(id, callback));
        },
        getContent: (id, api, callback) => {
            dispatch(getApiStandardAction(id, api, callback));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApiStandard));

import {createStore, applyMiddleware} from 'redux'

import combineReducers from './reducers/index'
import thunk from 'redux-thunk'

const initialState = {
    
}

const middleware = [thunk];

const store = createStore(
    combineReducers,
    initialState,
    applyMiddleware(...middleware)
)

export default store;
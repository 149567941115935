import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getProductsStructureAction } from '../../store/actions/apiActions';

import './ApiList.css';

function ApiList({ products, getProducts, match }) {
  const productId = match.params.productId;

  useEffect(() => {
    getProducts();
  }, [productId]);

  if (products === null || products === undefined) return null;

  let product = products.filter((item) => item.id === productId)[0];

  if (!product) return null;

  return (
    <div className="api-list">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <p>
              <a href={`${process.env.REACT_APP_WEB_ROOT}/products/`}>
                &lt;&nbsp;Вернуться к&nbsp;списку API продуктов
              </a>
            </p>

            <h2>{product.name} - список API</h2>
          </div>

          <div className="col-1"></div>
        </div>

        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <p className="lead">{product.description}</p>
            <hr />
            <br />
          </div>
          <div className="col-lg-1"></div>
        </div>

        {product.APIS.map((item) => {
          // console.error(item);
          return (
            <div className="row" key={item.id}>
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <h3>
                  <a href={`${process.env.REACT_APP_WEB_ROOT}/api/${item.id}`}>
                    {item.name}
                  </a>
                </h3>

                <p className="lead">{item.description}</p>
                <a
                  className="btn btn-primary"
                  href={`${process.env.REACT_APP_WEB_ROOT}/api/${item.id}`}
                >
                  {item.mnemonicFull}
                </a>
                <hr />
              </div>
              <div className="col-lg-1"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    products: state.api.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: (id, callback) => {
      dispatch(getProductsStructureAction(id, callback));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApiList)
);

import React from 'react';


import './ApiFormInstance.css'
import formImage from './../../../../assets/img/icon02.svg'

function ApiFormInstance({ id, title, apiId }) {
    let url = `${process.env.REACT_APP_WEB_ROOT}/api/${apiId}/${id}`;
    return (
        <div className="form-instance">
            <div className="row product-icon" style={
                    {
                        // background: `url(${process.env.REACT_APP_SERVER_ROOT}${formImage})`
                    }
                }>
                <div className="col">
                    <a href={url}>
                    <img alt={title}
                    src={formImage}
                    className="img-fluid product-image" />
                    </a>
                </div>
            </div>

            <div className="row product-description">
                <div className="col">
                    <h4><a href={url}>{title}</a></h4>
                </div>
            </div>

           

        </div>
    );
}

export default ApiFormInstance
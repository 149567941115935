import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import NavigationBar from "./../../../components/navigation-bar/NavigationBar";
import ApiNavigationBar from "./../api-navigation-bar/ApiNavigationBar";

import ApiHeader from "./../api-header/ApiHeader";
import ApiEndpoint from "./../api-endpoint/ApiEndpoint";
import ApiMessages from "../api-messages/ApiMessages";
import ApiSandboxForm from "./../api-sandbox-form/ApiSandboxForm";
//import ConfluenceContent from './../confluence-content/ConfluenceContent'

import { API_SANDBOX_PATH } from "./../../../routes/index";

import {
  getApiStructureAction,
  getApiOperationAction
} from "../../../store/actions/apiActions";

// import { Nav, NavDropdown } from "react-bootstrap";

import "./ApiOperation.css";

function ApiOperation({ api, getApi, content, getContent, match }) {
  const apiId = match.params.apiId;
  const bucId = "BUC" + match.params.bucId;
  const id = match.params.operId;

  //const [sandboxOpened, setSandboxOpened] = useState(localStorage.getItem("sandbox-window") || false );
  const [sandboxOpened, setSandboxOpened] = useState(false );

  const viewPortWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

  useEffect(() => {
    getApi(apiId, api => {
      if (api) getContent(id, api);
    });
  }, [id, apiId, bucId]);

  if (
    api === null ||
    api === undefined ||
    content === null ||
    content === undefined
  )
    return null;

  const buc = api.getBucById(bucId);
  const oper = api.getOperationById(id);

  let bucInfo = api.clearName(buc.name);
  let operInfo = api.clearName(oper.name);

  let samplesCount = 0;

  api.operations.map(o => (o.samples.length ? samplesCount++ : undefined));

  function openSandbox(opened) {
    setSandboxOpened(opened);
  }

let buttonPositionStyle ={}

// if (!sandboxOpened) {
//   buttonPositionStyle.left=320-105;
//   console.log(viewPortWidth, buttonPositionStyle)
// }



  return (
    <React.Fragment>
      <NavigationBar />
      <div className="api-operation">
        <div className="container-fluid">
          <ApiHeader name={api.name} />
          <ApiNavigationBar api={api} />

          <div className="api-operation-content">
            <div className="row">
              <div className="col-1"></div>
              <div className="col-10">
                <h2>
                  Операция: <small>{id}</small> {operInfo.name}
                </h2>
              </div>
              <div className="col-1"></div>
            </div>

            <div className="row">
              <div className="col-1"></div>
              <div className="col-10">
                <h3>
                  Сценарий: <small>{bucId}</small> {bucInfo.name}
                </h3>
              </div>
              <div className="col-1"></div>
            </div>

            <ApiEndpoint api={api} operId={id} endpoint={content} />

            {!!samplesCount && (
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <h4>
                    <a
                      href={`${process.env.REACT_APP_WEB_ROOT}/api/${match.params.apiId}/sandbox`}
                      className="btn btn-primary"
                    >
                      Попробовать это API в песочнице
                    </a>
                  </h4>
                </div>
                <div className="col-1"></div>
              </div>
            )}

            <ApiMessages api={api} operation={oper} />
          </div>
        </div>
      </div>
      {/* <div className={sandboxOpened? "sandbox-modal" : "sandbox-modal pin" }  >
        <ApiSandboxForm currentOperation={false} embeded={true} openSandbox={openSandbox} />
      </div> */}
    </React.Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    api: state.api.structure,
    content: state.api ? state.api.operation : ""
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getApi: (id, callback) => {
      dispatch(getApiStructureAction(id, callback));
    },
    getContent: (id, api, callback) => {
      dispatch(getApiOperationAction(id, api, callback));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ApiOperation)
);

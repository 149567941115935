import React, { useEffect } from 'react';

import { withRouter } from 'react-router-dom'

import './ConfluenceContent.css'

function ConfluenceContent( {
    content,
    noColumns
}) {

    useEffect(() => {

    });

    if (content === null || content === undefined) return null;

    return (
        <div className="confluence-content">
            <div className="row">

                {noColumns && (
                    <>                    
                    <div className="col-12">
                        <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>
                    </div>                    
                    </>
                )}

                {!noColumns && (
                    <>
                    <div className="col-1"></div>
                    <div className="col-10">
                        <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>
                    </div>
                    <div className="col-1"></div>
                    </>
                )}                
            </div>
        </div>
    )
}


export default withRouter(ConfluenceContent);
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import NavigationBar from './../../../components/navigation-bar/NavigationBar'
import ApiNavigationBar from './../api-navigation-bar/ApiNavigationBar'


import ApiHeader from './../api-header/ApiHeader'
import ConfluenceContent from './../confluence-content/ConfluenceContent'
import ApiSandboxForm from "./../api-sandbox-form/ApiSandboxForm";

import {
    getApiStructureAction,
    getApiDescriptionAction
} from '../../../store/actions/apiActions'

import './ApiDescription.css'

import {
    Image
} from 'react-bootstrap';

function ApiDescription({
    api,
    getApi,
    content,
    getContent,
    match
}) {

    const apiId = match.params.apiId;
    //const [sandboxOpened, setSandboxOpened] = useState(localStorage.getItem("sandbox-window") || false );
    const [sandboxOpened, setSandboxOpened] = useState( false );

    function openSandbox(opened) {
        setSandboxOpened(opened);
      }

    useEffect(() => {
        getApi(apiId, (api) => {
            if (api) getContent(api);
        });
    }, [apiId]);

    if (api === null || api === undefined || content === null || content === undefined) return null;

    console.log(api)
    return (
        <React.Fragment>
             <NavigationBar />
            <div className="api-description">

                <div className="container-fluid">
                    <ApiHeader name={api.name} />
                    <ApiNavigationBar api={api} />

                    <ConfluenceContent content={content} />
                    {api.id === "A41" &&
                    <div className="confluence-content">
                        <div className="row">
                            <div className="col-1"></div>
                            <div className="col-10">
                                <a href="img/api41.png" target="_blank"> <Image src="img/api41.png" fluid /> </a>
                            </div>

                            <div className="col-1"></div>
                        </div>
                    </div>}
                </div>

            </div>
            {/* <div className={sandboxOpened? "sandbox-modal" : "sandbox-modal pin" }  >
                <ApiSandboxForm currentOperation={false} embeded={true} openSandbox={openSandbox} />
            </div> */}
        </React.Fragment>
    )
}


const mapStateToProps = (state, ownProps) => {
    return {
        api: state.api.structure,
        content: state.api ? state.api.description : ''
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getApi: (id, callback) => {
            dispatch(getApiStructureAction(id, callback));
        },
        getContent: (api, callback) => {
            dispatch(getApiDescriptionAction(api, callback));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApiDescription));

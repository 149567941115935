import './ApiNavigationBar.css';

import React, { useEffect } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getFormsStructureAction } from '../../../store/actions/apiActions';
import {
  API_BUC_PATH,
  API_DESCRIPTION_PATH,
  API_DICTIONARY_PATH,
  API_FORM_PREVIEW_PATH,
  API_OPERATION_PATH,
  API_PROCEDURE_PATH,
  API_SANDBOX_PATH,
  API_STANDARD_PATH,
  SWAGGER_PATH,
} from './../../../routes';

function renderBucs({ settings, businessCases }, match) {
  if (!settings.showBusinessCases || !businessCases.length) return null;

  //const apiId = match.params.apiId + '/';
  const isActive = match.path === API_BUC_PATH;

  return (
    <NavDropdown active={isActive} title="Сценарии" id="bucs-dropdown">
      {businessCases.map((item) => {
        return (
          <NavDropdown.Item
            active={item.id === `BUC${match.params.bucId}`}
            key={item.id}
            href={`${process.env.REACT_APP_WEB_ROOT}/api/${item.apiId}/${item.id}`}
            eventKey={item.id}
          >
            {item.name}
          </NavDropdown.Item>
        );
      })}
    </NavDropdown>
  );
}

function renderOperations({ settings, operations }, match) {
  if (!settings.showOperations || !operations.length) return null;

  //const apiId = match.params.apiId + '/';
  const isActive = match.path === API_OPERATION_PATH;

  return (
    <NavDropdown
      active={isActive}
      title="Операции API"
      id="operations-dropdown"
    >
      {operations.map((item) => {
        return (
          <NavDropdown.Item
            active={item.id === `${match.params.operId}`}
            key={item.id}
            href={`${process.env.REACT_APP_WEB_ROOT}/api/${item.apiId}/${item.bucId}/${item.id}`}
            eventKey={item.id}
          >
            {item.name}
          </NavDropdown.Item>
        );
      })}
    </NavDropdown>
  );
}

function renderDictionaries({ settings, dictionaries }, match) {
  if (!settings.showDictionaries || !dictionaries.length) return null;

  const apiId = match.params.apiId;
  const isActive = match.path === API_DICTIONARY_PATH;

  let dictId = `${match.params.dictPart}.DICT.${match.params.apiPart}`;
  return (
    <NavDropdown
      active={isActive}
      title="Справочники"
      id="dictionaries-dropdown"
    >
      {dictionaries.map((item) => {
        return (
          <NavDropdown.Item
            active={item.id === dictId}
            key={item.id}
            href={`${process.env.REACT_APP_WEB_ROOT}/api/${apiId}/${item.id}`}
            eventKey={item.id}
          >
            {item.name}
          </NavDropdown.Item>
        );
      })}
    </NavDropdown>
  );
}

function renderForms({ settings }, forms, match) {
  if (!settings.showForms || !forms.length) return null;

  const apiId = match.params.apiId;
  const isActive = match.path === API_FORM_PREVIEW_PATH;

  let currentForms = forms.filter((item) => item.apiId === apiId);

  if (!currentForms.length) return null;

  let formId = `${match.params.formPart}.FORM.${match.params.apiPart}`;
  //let url = `${process.env.REACT_APP_WEB_ROOT}/api/${apiId}/forms`;
  // <Nav.Link href={`${process.env.REACT_APP_WEB_ROOT}/api/${match.params.apiId}/forms`} active={match.path === API_FORMS_PATH} eventKey="forms">Визуальные формы</Nav.Link>
  return (
    <NavDropdown active={isActive} title="Визуальные формы" id="forms-dropdown">
      {currentForms.map((item) => {
        if (item.apiId === apiId) {
          return (
            <NavDropdown.Item
              active={item.id === formId}
              key={item.id}
              href={`${process.env.REACT_APP_WEB_ROOT}/api/${item.apiId}/${item.id}`}
              eventKey={item.id}
            >
              {item.title}
            </NavDropdown.Item>
          );
        }
        return undefined;
      })}
    </NavDropdown>
  );
}

function renderProcedures({ settings, procedures }, match) {
  if (!settings.showProcedures || !procedures.length) return null;

  const apiId = match.params.apiId;
  const isActive = match.path === API_PROCEDURE_PATH;
  let procId = `CHEC${match.params.checkNum}.BUC${match.params.bucId}.${match.params.apiPart}`;

  return (
    <NavDropdown active={isActive} title="Логика" id="procedures-dropdown">
      {procedures.map((item) => {
        return (
          <NavDropdown.Item
            active={item.id === procId}
            key={item.id}
            href={`${process.env.REACT_APP_WEB_ROOT}/api/${apiId}/${item.id}`}
            eventKey={item.id}
          >
            {item.name}
          </NavDropdown.Item>
        );
      })}
    </NavDropdown>
  );
}

function renderStandards({ settings, standards }, match) {
  if (!settings.showStandards || !standards.length) return null;

  const apiId = match.params.apiId;
  const isActive = match.path === API_STANDARD_PATH;
  let standardId = `STD${match.params.stdNum}.${match.params.stdPostfix}.${match.params.apiPart}`;

  return (
    <NavDropdown active={isActive} title="Стандарты" id="standards-dropdown">
      {standards.map((item) => {
        return (
          <NavDropdown.Item
            active={item.id === standardId}
            key={item.id}
            href={`${process.env.REACT_APP_WEB_ROOT}/api/${apiId}/${item.id}`}
            eventKey={item.id}
          >
            {item.name}
          </NavDropdown.Item>
        );
      })}
    </NavDropdown>
  );
}

function ApiNavigationBar({ forms, getForms, match, api }) {
  useEffect(() => {
    if (!forms) getForms();
  }, [forms, api]);

  if (!api || !forms) return null;

  let samplesCount = 0;
  //console.log('api', api);

  api.operations.map((o) => (o.samples.length ? samplesCount++ : undefined));

  return (
    <div className="api-navigation-bar">
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link
                href={`${process.env.REACT_APP_WEB_ROOT}/api/${match.params.apiId}`}
                active={match.path === API_DESCRIPTION_PATH}
                eventKey="desc"
              >
                Описание
              </Nav.Link>
            </Nav.Item>
            {renderBucs(api, match)}
            {renderOperations(api, match)}
            {renderProcedures(api, match)}
            {renderStandards(api, match)}
            {renderDictionaries(api, match)}

            {(api.id === 'A13' || api.id === 'A18') && (
              <Nav.Link
                style={{ fontSize: 19 }}
                href={`${process.env.REACT_APP_WEB_ROOT}/swagger/${match.params.apiId}/`}
                active={match.path === SWAGGER_PATH}
                eventKey="swagger"
              >
                Swagger
              </Nav.Link>
            )}

            {api.id === 'A24' && (
              <Nav.Link
                style={{ fontSize: 19 }}
                href={`${process.env.REACT_APP_WEB_ROOT}/swagger/${match.params.apiId}/`}
                active={match.path === SWAGGER_PATH}
                eventKey="swagger"
              >
                Swagger
              </Nav.Link>
            )}

            {api.id === 'A26' && (
              <Nav.Link
                style={{ fontSize: 19 }}
                href={`${process.env.REACT_APP_WEB_ROOT}/swagger/${match.params.apiId}/`}
                active={match.path === SWAGGER_PATH}
                eventKey="swagger"
              >
                Swagger
              </Nav.Link>
            )}

            {samplesCount > 0 && (
              <Nav.Item>
                <Nav.Link
                  href={`${process.env.REACT_APP_WEB_ROOT}/api/${match.params.apiId}/sandbox`}
                  active={match.path === API_SANDBOX_PATH}
                  eventKey="sandbox"
                >
                  Песочница
                </Nav.Link>
              </Nav.Item>
            )}

            {renderForms(api, forms, match)}
          </Nav>
        </div>

        <div className="col-1"></div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    forms: state.api.forms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getForms: (id, callback) => {
      dispatch(getFormsStructureAction(id, callback));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApiNavigationBar)
);

import he from 'he';
import _ from 'underscore';
import XRegExp from 'xregexp';

const PageUrlRX = XRegExp(
  `(?m)<a (class=\\"confluence-link\\" )?(?<href>href=\\"\\/pages\\/viewpage\\.action\\?pageId=[0-9]{9}\\") (?<resourceid>data-linked-resource-id=\\"[0-9]{9}\\") (?<version>data-linked-resource-version=\\"[0-9]{1,9}\\") (?<type>data-linked-resource-type=\\"page\\") (?<alias>data-linked-resource-default-alias=\\"[а-яА-Яa-zA-Z0-9, \\._#-]{1,255}\\") (?<baseurl>data-base-url=\\"https:\\/\\/confluence\\.raiffeisen\\.ru\\")>(?<text>[а-яА-Яa-zA-Z0-9, \\._#-]{1,255})<\\/a>`
);
const PageLightUrlRX = XRegExp(
  `(?m)<a (?<href>href=\\"(https:\\/\\/confluence.raiffeisen.ru)?\\/pages\\/viewpage\\.action\\?pageId=[0-9]{9}([a-zA-Z0-9&;=]{0,32})\\"([a-zA-Z0-9_\\-&; =\\"]{0,512}))>(?<text>[а-яА-Яa-zA-Z0-9ёЁ, \\._#-]{1,255})<\\/a>`
);
const AttachmentUrlRX = XRegExp(
  `(?m)<a (class="confluence-link" )?href="(?<href>\\/download\\/attachments\\/[0-9a-zA-Z\\.=\\&;\\?\\-"\\/_ ]{1,128})"([a-zA-Z0-9+\\"=\\/\\\\\\.&;_\\?,\\- ]){0,512}>(?<text>[0-9a-z&а-яА-ЯёЁ+A-Z_\\.]{1,32})<\\/a>`
);
const EndpointRX = XRegExp(
  `(?m)>?(?<endpoint>\\.\\.[a-zA-Z0-9#_\\/\\.&;\\-\\?={}<>" ]{1,1255})(<br \\/>)?(<\\/p>)`
);
const ImgPluginRX = XRegExp(
  `(?<plugin><img class="editor-inline-macro" [a-zA-Z0-9а-яА-Я"\\/&,\\.;=\\?_ -]{1,512} data-macro-schema-version="[0-9]{1,5}">)`
);
const LastColumnWidthRX = XRegExp(
  `(?m)<col style="width: (?<width>[0-9]{3,4})\\.0px;" \\/>`
);
const SampleRX = XRegExp(`(?m)<pre.*?>(?<message>.*?)</pre>`);
const DictionariesRX = XRegExp(
  `(?m)<div(.*?)>([\\r\\n\\t ]{0,5})<div(.*?)>([\\r\\n\\t ]{0,5})<b>(?<name>.*?)<\\/b>([\\r\\n\\t ]{0,5})<\\/div>([\\r\\n\\t ]{0,5})<div(.*?)>([\\r\\n\\t ]{0,5})<pre(.*?)>(?<endpoint>.*?)<\\/pre>([\\r\\n\\t ]{0,5})<\\/div>([\\r\\n\\t ]{0,5})<\\/div>([\\r\\n\\t ]{0,5})<p(.*?)>(?<description>.*?)<\\/p>`
);
const DictionaryArticleRX = XRegExp(
  `(?m)(?<article>(<p(.*?)>(.*?)<\\/p>){1,1})([\\r\\n\\t ]{0,5})<div(.*?)>`
);
const DotRX = XRegExp(`[a-z]\\.`);
const ImgRx = XRegExp(`<img(.*)>`);

// <div(.*?)>([\\r\\n\\t ]{0,5})<div(.*?)>([\\r\\n\\t ]{0,5})<b>(?<name>.*?)<\\/b>([\\r\\n\\t ]{0,5})<\\/div>([\\r\\n\\t ]{0,5})<div(.*?)>([\\r\\n\\t ]{0,5})<pre(.*?)>(?<endpoint>.*?)<\\/pre>

function extract(resourceId, html, removeUrls) {
  if (typeof html !== 'string') return '';

  let result = clearHtml(changeUrls(resourceId, html, removeUrls));
  return result;
}

function clearHtml(html) {
  html = html.trim();
  if (html.indexOf('<p>&nbsp;</p>') === 0) html = html.substr(13);

  html = html.split('<p><strong><br /></strong></p>').join('');
  html = html.split('<p></p>').join('');
  html = html.split('<p><br/></p>').join('');
  html = html.split('<span style="color: rgb(80,80,80);">').join('');
  html = html
    .split('<span class="plugin_pagetree_status hidden">Collapse all</span>')
    .join('');
  html = html
    .split('<a class="plugin_pagetree_expandall" href="#">Expand all</a>')
    .join('');
  html = html
    .split('<a class="plugin_pagetree_collapseall" href="#">Collapse all</a>')
    .join('');

  let match = XRegExp.exec(html, ImgPluginRX);

  if (match && match.plugin) {
    html = html.split(match.plugin).join('');
  }

  XRegExp.forEach(html, ImgRx, (match, i) => {
    html = html.split(match[0]).join('');
  });

  XRegExp.forEach(html, LastColumnWidthRX, (match, i) => {
    let width = match.width * 1;

    if (width >= 200) {
      let changeTo = `"<col style="max-width: ${width}.0px;" />"`;
      html = html.split(match[0]).join(changeTo);
    }
  });

  XRegExp.forEach(html, DotRX, (match, i) => {
    html = html.split(match[0]).join(match[0].substr(0, 1) + '.\r\n');
  });

  //html = html.split('.').join('.\r\n');

  // html = html.split('<col /><col /><col /><col /></colgroup>').join('<col /><col /><col /><col style="width: 35%" /></colgroup>')
  // html = html.split('<col /><col /><col /></colgroup>').join('<col /><col /><col style="width: 45%" /></colgroup>')

  html = html
    .split('<span class="plugin_pagetree_status hidden">Collapse all</span>')
    .join('');

  html = html.split(' </h2>').join('</h2>');

  // let pStart = html.indexOf('<h2');
  // let pEnd = html.indexOf('ProxyEndpoint</h2>');
  // let pEnd2 = html.indexOf('Json Model</h2>');

  // let pEnd3 = html.indexOf('Endpoint</h2>');

  // //console.log('wtf', pStart, pEnd, pEnd2, html)
  // //
  // if ( pStart >= 0 && pEnd >= 0 && pEnd2 >= 0 && pEnd3 > 0) {
  //     var buf = html.substr(0, pEnd3 + 'Endpoint</h2>'.length)

  //     html = html.substr(pEnd3 + 'Endpoint</h2>'.length)

  //     html =  html.substr(pEnd + 'ProxyEndpoint</h2>'.length )
  //     pEnd2 = html.indexOf('Json Model</h2>');

  //     if (pEnd2 >= 0) {
  //         html = html.substr(pEnd2 + 'Json Model</h2>'.length);
  //     }

  // }

  return html;
}

function changeUrls(resourceId, html, removeUrls) {
  let parts = resourceId.split('.');
  let apiId = parts[parts.length - 1];

  XRegExp.forEach(html, PageLightUrlRX, (match, i) => {
    let link = parseLink(match.text);
    //console.error(link);

    let newUrl = removeUrls ? '' : `<a ${composeLink(link)}>${match.text}</a>`;
    html = html.split(match[0]).join(newUrl);
  });

  XRegExp.forEach(html, PageUrlRX, (match, i) => {
    let link = parseLink(match.text);
    let alias = match.alias
      .split('data-linked-resource-default-alias=')
      .join('')
      .split('"')
      .join('');
    let newUrl = removeUrls ? '' : `<a ${composeLink(link)}>${alias}</a>`;
    html = html.split(match[0]).join(newUrl);
  });

  XRegExp.forEach(html, AttachmentUrlRX, (match, i) => {
    let newUrl = `<a href="${process.env.REACT_APP_SERVER_ROOT}/storage/${apiId}/${resourceId}/attachments/${match.text}" target="_blank">${match.text}</a>`;
    html = html.split(match[0]).join(newUrl);
  });

  return html;
}

function composeLink(link) {
  let result = `href="#`;

  let messageId = `${link.message}.${link.buc}.${link.api}`;
  let operId = `${link.oper}`;
  let bucId = `${link.buc}.${link.api}`;
  let apiId = `${link.api}`;
  let dictId = `${link.dict}.DICT.${link.api}`;
  let checkId = `${link.check}.${bucId}`;
  let sampleId = `${link.sample}.${operId}`;
  let standardId = `${link.std}`;

  if (link.api && link.sample) {
    result = `${result}/api/${apiId}/${sampleId}`;
  } else if (link.api && link.check) {
    result = `${result}/api/${apiId}/${checkId}`;
  } else if (link.api && link.std) {
    result = `${result}/api/${apiId}/${standardId}`;
  } else if (link.api && link.dict) {
    result = `${result}/api/${apiId}/${dictId}`;
  } else if (link.api && link.buc && link.oper && link.message) {
    result = `${result}/api/${apiId}/${bucId}/${operId}/${messageId}`;
  } else if (link.api && link.buc && link.oper) {
    result = `${result}/api/${apiId}/${bucId}/${operId}`;
  } else if (link.api && link.buc) {
    result = `${result}/api/${apiId}/${bucId}`;
  } else if (link.api && link.buc) {
    result = `${result}/api/${apiId}`;
  } else result = result + '#';

  return result + '"';
}

function parseLink(text) {
  text = text || '';
  let pps = text.indexOf('-');

  if (pps > 0) text = text.substr(0, pps);

  let parts = text.trim().split('.');

  let link = {
    message:
      parts.length === 3 &&
      (parts[0].indexOf('REQ') === 0 ||
        parts[0].indexOf('RESP') === 0 ||
        parts[0].indexOf('ERROR') === 0)
        ? parts[parts.length - 3] || ''
        : '',
    oper: '',
    buc:
      parts.length >= 2 && parts[0].indexOf('STD') === -1 && parts[1] !== 'DICT'
        ? parts[parts.length - 2] || ''
        : '',
    api: parts[parts.length - 1],
    check:
      parts.length === 3 && parts[0].indexOf('CHECK') === 0 ? parts[0] : '',
    std: '',
  };

  let operIndex = 0;
  if (parts.length === 4 && parts[0].indexOf('SAMPLE') === 0) {
    operIndex = 1;
    link.sample = parts[0];
  }

  if (
    (parts.length === 3 || parts.length === 4) &&
    (parts[operIndex].indexOf('GET') === 0 ||
      parts[operIndex].indexOf('POST') === 0 ||
      parts[operIndex].indexOf('PATCH') === 0)
  ) {
    link.oper = parts.join('.');
  } else if (parts.length === 3 && parts[0].indexOf('STD') === 0) {
    link.std = parts.join('.');
  } else if (parts.length === 3 && parts[1] === 'DICT') {
    link.dict = parts[0];
  } else if (parts.length === 3 && parts[1] === 'FORM') {
    link.form = parts[0];
  }

  if (!link.oper && link.message && link.buc && link.api) {
    let messageId = `${link.message}.${link.buc}.${link.api}`;

    let message = structure.getMessageById(messageId); //structure.getMessageById(`${link.message}/${link.buc}.${link.api}`);
    if (message) {
      link.oper = message.operId;
      link.method = message.operId.split('.')[0];
    }
  }

  return link;
}

function deleteSpanInHtml(html) {
  let ppsStart = html.indexOf('<span style="color:');
  let ppsEnd = html.indexOf(');">');

  if (ppsStart >= 0 && ppsEnd >= 0 && ppsEnd > ppsStart) {
    html = html.substr(0, ppsStart) + html.substr(ppsEnd + 4);
    return {
      html: html,
      changed: true,
    };
  }
  return {
    html: html,
    changed: false,
  };
}

function clearSpans(html) {
  let res = { html: html, changed: true };

  while (res.changed) {
    res = deleteSpanInHtml(res.html);
  }
  html = res.html.split('</span>').join('');

  return html;
}

function extractEndpoint(html, needLog = false) {
  html = html.split('&lt;').join('<').split('&gt;').join('>');

  html = clearSpans(html);

  //console.error(html);

  let match = XRegExp.exec(html, EndpointRX);

  // if (needLog) {
  //     console.log(html, match)
  // }

  //console.error(html);
  if (match && match.endpoint) {
    let result = match.endpoint
      .split(`<span class="external-link">`)
      .join('')
      .split(`<span>`)
      .join('')
      .split(`<br />`)
      .join('')
      .split('<span class="nolink">')
      .join('')
      .split(`</span>`)
      .join('')
      .split(`<span class="external-link" />`)
      .join('');

    //console.log('WWWW', html);

    let pps = result.indexOf('</p>');
    if (pps > 0) result = result.substr(0, pps);

    result = he.decode(result).trim();

    while (result.indexOf('.') === 0) {
      result = result.substr(1);
    }

    if (result.indexOf('/') !== 0) result = '/' + result;
    return result;
  }
  return '';
}

function extractSample(html) {
  html = html.split('\r').join('').split('\n').join('').trim();

  html = _.unescape(html);

  let match = XRegExp.exec(html, SampleRX);

  // ../openapi-006/dictionaries/countries/?systemCode=BPMPL</p><p><strong>Json Model</strong>

  if (match && match.message) {
    let result = match.message;

    return result;
  }
  return '';
}

// function removeDictionaryEndpoint(html) {
//     let match = XRegExp.exec(html, EndpointRX);

//     if (match && match.endpoint) {
//         let toRemove = match[0];
//         let pps = toRemove.indexOf('</p>');
//         if (pps > 0) toRemove = toRemove.substr(0, pps);
//         html = html.split(toRemove).join('');
//     }
//     return html;
// }

function download(url) {
  //console.log(`${process.env.REACT_APP_SERVER_ROOT}/storage/${url}`);
  return fetch(`${process.env.REACT_APP_SERVER_ROOT}/storage/${url}`).then(
    (resp) => {
      return resp.text();
    }
  );
}

function makeStructure(object) {
  object.getBucById = (id) =>
    object.businessCases.filter((item) => item.id === id)[0];
  object.getOperationById = (id) =>
    object.operations.filter((item) => item.id === id)[0];
  object.getStandardById = (id) =>
    object.standards.filter((item) => item.id === id)[0];
  object.getDictionaryById = (id) =>
    object.dictionaries.filter((item) => item.id === id)[0];
  object.getProcedureById = (id) =>
    object.procedures.filter((item) => item.id === id)[0];
  object.getMessageById = (id) => {
    for (let i = 0; i < object.operations.length; i++) {
      for (let j = 0; j < object.operations[i].messages.length; j++) {
        let message = object.operations[i].messages[j];
        if (message.id === id) return message;
      }
    }
  };
  object.clearName = (name) => {
    let pps = name.indexOf('-');

    if (pps > 0) {
      let link = parseLink(name);
      let result = name.substr(pps + 1).trim();

      return {
        name: result,
        link: link,
      };
    }
    return {
      name: name,
      link: {},
    };
  };
  return object;
}

let structure = {};
let products = {};

const ContentService = {
  getProductsStructure: () => {
    return download('products/products.json').then((text) => {
      products = JSON.parse(text);
      return products;
    });
  },

  getFormsStructure: () => {
    return download('forms/forms.json').then((text) => {
      products = JSON.parse(text);
      return products;
    });
  },

  getStructure: (apiId) => {
    return download(apiId + '/structure.json').then((text) => {
      structure = makeStructure(JSON.parse(text));
      return structure;
    });
  },
  getDescription: (apiId, filename) => {
    return download(apiId + filename).then((data) => {
      let result = extract(apiId, data);

      return result;
    });
  },
  getBuc: (bucId, apiId, filename) => {
    return download(`${apiId}/${bucId}${filename}`).then((data) =>
      extract(bucId, data)
    );
  },
  getOperation: (operId, apiId, filename) => {
    return download(`${apiId}/${operId}${filename}`).then((data) =>
      extractEndpoint(extract(operId, data))
    );
  },
  getStandard: (standardId, apiId, filename) => {
    return download(`${apiId}/${standardId}${filename}`).then((data) => {
      let html = extract(standardId, data);
      return html;
    });
  },

  getDictionary: (dictId, apiId, filename) => {
    return download(`${apiId}/${dictId}${filename}`).then((data) => {
      let html = extract(dictId, data, false);

      let dictionaryArticle = '';
      html = html.split('\r\n').join('');
      html = html.split('\r').join('');
      html = html.split('\n').join('');

      //let match = XRegExp.exec(html, DictionaryArticleRX);
      //console.error(match);

      //if (match && match.article) dictionaryArticle = match.article;

      let dictionaries = [];

      // console.log(match)
      // console.log(html)

      // XRegExp.forEach(html, DictionariesRX, (match, i) => {

      //     let endpoint = match.endpoint;
      //     //console.error(match, endpoint);
      //     while(endpoint.indexOf('.') === 0) {
      //         endpoint = endpoint.substr(1);
      //     }

      //     let dict = {
      //         name: match.name,
      //         description: '', //match.description,
      //         endpoint: _.unescape(endpoint)
      //     };
      //     dictionaries.push(dict);
      // });

      html = clearSpans(html);
      // console.log(html)

      html = html.split('<br/>').join('');

      let dict = {
        name: dictId,
        description: '', //match.description,
        endpoint: extractEndpoint(html), //_.unescape(endpoint)
      };

      dictionaries.push(dict);

      let pps1 = html.indexOf(' style="');
      let pps2 = html.indexOf('"', pps1 + 1);

      if (pps1 >= 0 && pps2 >= 0) {
        html = `${html.substr(0, pps1 + 1)}data-t="${html.substring(pps2 + 1)}`;
      }

      pps1 = html.indexOf('</p><h2');

      if (pps1 >= 0) {
        html = html.substring(pps1 + 4);
        //console.log('DESCTR',description);
      }

      return {
        html: html,
        dictionaries: dictionaries,
      };
    });
  },

  getProcedure: (procId, apiId, filename) => {
    return download(`${apiId}/${procId}${filename}`).then((data) => {
      let html = extract(procId, data);

      return html;
    });
  },

  getOperationMessage: (messageId, apiId, filename) => {
    return download(`${apiId}/${messageId}${filename}`).then((data) =>
      extract(messageId, data)
    );
  },

  getSample: (sampleId, apiId, filename) => {
    return download(`${apiId}/${sampleId}${filename}`).then((data) => {
      let html = extract(sampleId, data);
      let endpoint = extractEndpoint(html, false);

      //console.log('DDBG',`${apiId}/${sampleId}${filename}`, endpoint)

      let sandboxEndpoint = '';
      let readOnlyEndpoint = '';

      endpoint = endpoint
        .split('-<mod>/')
        .join('-snd/')
        .split('-{mod}/')
        .join('-snd/');
      // console.log('sbe', endpoint);

      let postfixLength = '-snd/'.length;
      let pps = endpoint.indexOf('-snd/');
      if (pps === -1) {
        pps = endpoint.indexOf('dlpi-dev/');

        if (pps >= 0) {
          postfixLength = 'dlpi-dev/'.length;
        } else {
          pps = endpoint.indexOf('ssb/');

          if (pps >= 0) {
            postfixLength = 'ssb/'.length;
          }
        }
      }
      let message = extractSample(html);

      if (
        clearSpans(message)
          .split('{')
          .join('')
          .split('}')
          .join('')
          .split('&nbsp;')
          .join('')
          .split(' ')
          .join('') === 'multipart/form-data'
      ) {
        message = 'multipart/form-data';
      }

      if (apiId === 'A18' && sampleId.indexOf('.POST.BUC8.A18') >= 0) {
        message = 'multipart/form-data';
        //console.log(apiId, sampleId);
        // console.log(endpoint, pps);
      }

      if (pps > 0) {
        sandboxEndpoint = endpoint.substr(pps + postfixLength);
        readOnlyEndpoint = endpoint.substr(0, pps + postfixLength);
      }

      let description = '';
      html = clearSpans(html);

      html = html
        .split('<span class="nolink">')
        .join('')
        .split('</span>')
        .join('')
        .split('<br/>')
        .join('');

      let pps1 = html.indexOf(' style="');
      let pps2 = html.indexOf('"', pps1 + 1);

      if (pps1 >= 0 && pps2 >= 0) {
        html = `${html.substr(0, pps1 + 1)}data-t="${html.substring(pps2 + 1)}`;
      }

      pps1 = html.indexOf('<h');
      pps2 = html.indexOf('<div class="code panel pdl');

      //console.log(pps1,pps2,html)
      if (pps1 >= 0 && pps2 >= 0) {
        description = html.substring(pps1, pps2);

        //console.log('DESCTR',description);
      } else {
        pps1 = html.indexOf('<p style="">');
        if (pps1 === -1) pps1 = html.indexOf('<p>');

        if (pps1 >= 0) {
          description = html.substring(pps1);

          if (description.indexOf(endpoint) >= 0) {
            pps1 = html.indexOf('</p>');

            if (pps1 >= 0) {
              description = html.substring(pps1 + '</p>'.length);
            }
          }

          pps2 = description.indexOf('<pre');
          if (pps2 >= 0) {
            description = description.substring(0, pps);
          }
        }
      }

      // if (readOnlyEndpoint.indexOf('ssb') >=0 ) {
      //     readOnlyEndpoint=readOnlyEndpoint.substring('/private'.length)
      // }

      //console.error(html)

      let content = {
        method: sampleId.split('.')[1],
        endpoint: endpoint,
        readOnlyEndpoint: readOnlyEndpoint,
        sandboxEndpoint: sandboxEndpoint,
        description: description,
        message: message,
      };

      return content;
    });
  },
};

export default ContentService;

import './ApiEndpoint.css';

import React from 'react';
import { Badge } from 'react-bootstrap';

import { ENDPOINT_PREFIX } from './../../../services/contentService/constants';

function renderSsl(needMode, entity, endpoint, method, dictionary) {
  if (!needMode || dictionary) return null;
  endpoint = endpoint
    .replace("raiffeisen.ru/", "raiffeisen.ru/private/")
    .replace("<mod>", "ssl")
    .replace("{mod}", "ssl");

  if (entity.mods.indexOf("ssl") >= 0) {
    return (
      <>
        <p>Доступ по клиентскому сертификату:</p>
        <p>
          <Badge variant="secondary">{method}</Badge>{" "}
          <span className="endpoint-value">{endpoint}</span>
        </p>

        {endpoint.indexOf("mod") >= 0 && (
          <>
            <p>Прочие режимы доступа</p>
          </>
        )}
      </>
    );
  }
  return null;
}

function renderMod(needMode, entity, endpoint, dictionary) {
  if (!needMode) return null;

  //console.log("endpoint", endpoint);
  if (isDLPIEndpoint(endpoint)) {
    return (
      <>
        <p>
          Возможные значения <strong>&lt;mod></strong>:
        </p>
        <ul>
          <li>
            <strong>dlpi-dev</strong> - песочница (используется для отладки,
            авторизация не требуется)
          </li>
          <li>
            <strong>dlpi-partner</strong> - продуктивная среда (требуется SSL
            сертификат)
          </li>
        </ul>
      </>
    );
  }

  //let hasSsl = false;
  return (
    endpoint.indexOf("mod") >= 0 && (
      <React.Fragment>
        <p>
          Возможные значения <strong>&lt;mod></strong>:
        </p>
        <ul>
          {entity.mods.map((item) => {
            switch (item) {
              //case 'ssl':
              //hasSsl = true;
              //return <li key={item}><strong>ssl</strong> - доступ по клиентскому сертификату (продуктив)</li>
              case "2fa":
                return (
                  <li key={item}>
                    <strong>2fa</strong> - доступ через Bearer Token (продуктив)
                  </li>
                );
              case "opn":
                return (
                  <li key={item}>
                    <strong>opn</strong> - доступ без авторизации (продуктив)
                  </li>
                );
              case "snd":
                return (
                  <li key={item}>
                    <strong>snd</strong> - доступ для отладки интеграции
                    (sandbox), без авторизации
                  </li>
                );
              default:
                return null;
            }
          })}
        </ul>
      </React.Fragment>
    )
  );
}

const isDLPIEndpoint = (url) => {
  if (url.indexOf("v1/applications") >= 0) {
    return true;
  }
  return false;
};

function ApiEndpoint({ api, sandbox, operId, endpoint, dictId }) {
  if (api === null || api === undefined) return null;
  let operation = null;
  let dictionary = null;
  let needMode = false;

  if (endpoint.indexOf("http") !== 0) {
    needMode = true;
    endpoint = ENDPOINT_PREFIX + endpoint;
  }

  let method = "";
  if (!dictId) {
    operation = api.operations.filter((item) => item.id === operId)[0];
    method = operation.id.split(".")[0];
  } else {
    dictionary = api.dictionaries.filter((item) => item.id === dictId)[0];
    method = "GET";
  }

  if (sandbox) endpoint.split("<mod>").join("snd");
  return (
    <div className="api-endpoint">
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10">
          <h4>Endpoint:</h4>

          {isDLPIEndpoint(endpoint) ? (
            <>
              <Badge variant="secondary">POST</Badge>{" "}
              <span className="endpoint-value">{endpoint}</span>
              <br />
              <br />
            </>
          ) : (
            renderSsl(
              needMode,
              operation ? operation : dictionary,
              endpoint,
              method
            )
          )}
          {endpoint.indexOf("-mod") >= 0 && (
            <p>
              <Badge variant="secondary">{method}</Badge>{" "}
              <span className="endpoint-value">{endpoint}</span>
            </p>
          )}

          {sandbox
            ? null
            : renderMod(
                needMode,
                operation ? operation : dictionary,
                endpoint,
                dictionary
              )}
        </div>
        <div className="col-1"></div>
      </div>
    </div>
  );
}

export default ApiEndpoint;

import React from 'react';


import './ApiProductHighlightItem.css'

function ApiProductHighlightItem({ id, title, description, apiCount, icon }) {
    return (
        <div className="api-product-highlight-item">
            <div className="row product-icon">
                <div className="col">
                    <a href={`${process.env.REACT_APP_WEB_ROOT}/products/${id}`}><img alt={title} src={`${process.env.REACT_APP_SERVER_ROOT}/storage/products/attachments/${icon}`} className="img-fluid product-image" /></a>
                </div>
            </div>

            <div className="row product-description">
                <div className="col">
                    <h3><a href={`${process.env.REACT_APP_WEB_ROOT}/products/${id}`}>{title}</a></h3>
                    <p className="product-description-text">
                        <a href={`${process.env.REACT_APP_WEB_ROOT}/products/${id}`}>{description}</a>
                    </p>
                </div>
            </div>

            <div className="row product-footer2">
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <hr className="product-divider" />
                        </div>
                    </div>

                    <div className="row no-gutters">
                        <div className="col-4">
                            <div className="product-api-count">
                                Кол-во API: {apiCount}
                            </div>
                        </div>
                        <div className="col-8">
                        <div className="product-cta">
                            <a className="product-cta-text" href={`${process.env.REACT_APP_WEB_ROOT}/products/${id}`}>Посмотреть&nbsp;API</a>
                        </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    );
}

export default ApiProductHighlightItem
import React from 'react';

import hero from './../../assets/img/hero.png';

import './WelcomeHero.css';

function WelcomeHero() {
  return (
    <div className="welcome-hero">
      <div className="container-fluid">
        <div className="row">
          <div className="col-1"></div>
          <div className="col col-lg-5">
            <h1>API Райффайзенбанка для&nbsp;разработчиков</h1>
            <p className="lead">
              Используйте API Райффайзенбанка для создания новых бизнес-моделей
              монетизации ваших приложений. Получите все необходимые
              инструменты, оперативную техническую поддержку и&nbsp;выплаты
              без&nbsp;задержек.
            </p>
            <p className="lead">
              Предоставьте вашим клиентам сервисы Райффайзенбанка
              в&nbsp;их&nbsp;любимом приложении.
            </p>
            <p>
              <a
                href={`${process.env.REACT_APP_WEB_ROOT}/products/`}
                className="btn btn-primary leadButton"
              >
                Посмотреть API продукты
              </a>
            </p>
          </div>

          <div className="col col-lg-5">
            <img alt="" src={hero} className="img-fluid hero-image" />
          </div>

          <div className="col-1"></div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeHero;

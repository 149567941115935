import React from 'react';

import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10 align-self-center">
            <p className="copyright">
              © 2003 – {new Date().getFullYear()} АО «Райффайзенбанк»
              <br />
              Генеральная лицензия Банка России № 3292 от 17.02.2015
            </p>
          </div>

          <div className="col-1"></div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

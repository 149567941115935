import React from 'react';
import './ApiHeader.css'

function ApiHeader({ name }) {
    return (
        <div className="api-header">
            <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                    <h1>{name}</h1>
                </div>
                <div className="col-1"></div>
            </div>
        </div>
    );
}

export default ApiHeader;
export const PRODUCTS_GET_STRUCTURE_FULFILLED = 'PRODUCTS_GET_STRUCTURE_FULFILLED'
export const FORMS_GET_STRUCTURE_FULFILLED = 'FORMS_GET_STRUCTURE_FULFILLED'
export const API_GET_STRUCTURE_FULFILLED = 'API_GET_STRUCTURE_FULFILLED'
export const API_GET_DESCRIPTION_FULFILLED = 'API_GET_DESCRIPTION_FULFILLED'
export const API_GET_BUC_FULFILLED = 'API_GET_BUC_FULFILLED'
export const API_GET_OPERATION_FULFILLED = 'API_GET_OPERATION_FULFILLED'
export const API_GET_OPERATION_MESSAGE_FULFILLED = 'API_GET_OPERATION_MESSAGE_FULFILLED'
export const API_GET_DICTIONARY_FULFILLED = 'API_GET_DICTIONARY_FULFILLED'
export const API_GET_PROCEDURE_FULFILLED = 'API_GET_PROCEDURE_FULFILLED'
export const API_GET_STANDARD_FULFILLED = 'API_GET_STANDARD_FULFILLED'
export const API_GET_SAMPLE_FULFILLED = 'API_GET_SAMPLE_FULFILLED'
export const API_CLEAR_SAMPLE_FULFILLED = 'API_CLEAR_SAMPLE_FULFILLED'



import React from 'react';
import { withRouter } from 'react-router-dom';
import NavigationBar from './../../components/navigation-bar/NavigationBar';

import WelcomeHero from './../../components/welcome-hero/WelcomeHero';
import ApiProductHighlights from './../../components/api-product-highlights/ApiProductHighlights';

import FormHighlights from './../../components/form-highlights/FormHighlights';

import GetStarted from '../../components/get-started/GetStarted';
import ContactHighlights from '../../components/contact-highlights/ContactHighlights';
// import ContactLightHighlights from '../../components/contact-light-highlights/ContactLightHighlights';

// import Footer from '../../components/footer/Footer';

function WelcomeView({ history, match }) {
  //console.error(match);

  //   useEffect(() => {
  //       if (match.path === "/forms") {
  //         window.scroll({
  //             behavior: 'smooth',
  //             left: 0,
  //             top: window.document.getElementsByName('form_highlights')[0].offsetTop
  //           });
  //       }

  // }, match.path);

  return (
    <React.Fragment>
      <NavigationBar />
      <WelcomeHero />
      <GetStarted />
      {/* <ContactHighlights /> */}
      <ApiProductHighlights />
      <FormHighlights />
      {/* <ContactLightHighlights /> */}
      {/* <ScenariosHighlights /> */}
    </React.Fragment>
  );
}

export default withRouter(WelcomeView);

import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'underscore';

import { Tab, Tabs, Card } from 'react-bootstrap';

import NavigationBar from './../../../../components/navigation-bar/NavigationBar';
import ApiNavigationBar from './../../api-navigation-bar/ApiNavigationBar';

import ApiHeader from './../../api-header/ApiHeader';

import {
  getApiStructureAction,
  getFormsStructureAction,
  getProductsStructureAction,
} from '../../../../store/actions/apiActions';

import './ApiFormPreview.css';

// import form_sample from './../../../../assets/img/form-hands.png'

function loadFormJs(jsUrl) {
  let scripts = document.getElementsByTagName('script');
  let found = false;
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src === jsUrl) {
      found = true;
    }
  }

  if (!found) {
    // setTimeout(() => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = jsUrl;
    script.charset = 'windows-1251';
    window.document.head.appendChild(script);
    // }, 1);
  }
}

function deleteForm(jsUrl, cssUrl) {
  if (window.OpenGUI) {
    delete window.OpenGUI;
  }
  deleteJs(jsUrl);
}

function deleteJs(jsUrl) {
  let scripts = document.getElementsByTagName('script');
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src === jsUrl) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }
  }
}

function ApiFormPreview({ api, getApi, forms, getForms, match }) {
  const apiId = match.params.apiId;
  const id = `${match.params.formPart}.FORM.${match.params.apiPart}`;

  let cssUrl = ''; //'http://s-msk-t-api-as1/opengui/rpartners/mgm/assets/css/opengui-vsesrazu-mgm.css';
  let jsUrl = ''; // 'http://s-msk-t-api-as1/opengui/rpartners/mgm/assets/js/opengui-vsesrazu-mgm.js';

  useEffect(() => {
    getApi(apiId, (data) => {
      getForms((formsData) => {
        let form = formsData.filter((item) => item.id === id)[0];
        cssUrl = form.cssUrl;
        jsUrl = form.jsUrl;
        loadFormJs(form.jsUrl);
      });
    });

    return () => {
      deleteForm(jsUrl, cssUrl);
    };
  }, []);

  if (
    api === null ||
    api === undefined ||
    forms === null ||
    forms === undefined
  )
    return null;
  let form = forms.filter((item) => item.id === id)[0];

  let divId = form.containerString.split('&quot;')[1];

  if (document.getElementById(divId)) {
    document
      .getElementById(divId)
      .scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
  }

  loadFormJs(form.jsUrl);

  let divString = _.unescape(form.containerString);

  let url = `${process.env.REACT_APP_WEB_ROOT}/api/${apiId}/forms`;

  return (
    <React.Fragment>
      <NavigationBar />
      <div className="api-buc">
        <div className="container-fluid">
          <ApiHeader name={api.name} />
          <ApiNavigationBar api={api} />

          <div className="api-forms-content">
            <div className="row">
              <div className="col-1"></div>
              <div className="col-10">
                <link rel="stylesheet" type="text/css" href={form.cssUrl} />
                <div dangerouslySetInnerHTML={{ __html: divString }}></div>
                {/* <div id="opengui-vsesrazu-mgm"></div> */}
              </div>
              <div className="col-1"></div>
            </div>

            <div className="row">
              <div className="col-1"></div>
              <div className="col-10 content-2fa">
                <h2>Как работает 2FA (двухфакторная) авторизация в API</h2>
                <ol>
                  <li>
                    <b>Отправка запроса на получение СМС-кода.</b>
                  </li>
                  <p>
                    Отправляется POST запрос с пустым содержимым на любой
                    endpoint операции сервиса с mod=2fa с добавлением двух HTTP
                    заголовков: HTTP_X_PHONE и Authorization.
                  </p>
                  <p>
                    В заголовке HTTP_X_PHONE указывается номер телефона
                    пользователя в виде 11 цифр, начиная с 8 (пример:
                    HTTP_X_PHONE=89161234567), а в заголовке Authorization -
                    константа Basic&nbsp;ZGJjYXJkbGVhZDpvYXBpK29ndWk=
                  </p>
                  <p>
                    В ответе сервис отправит пользователю СМС-код на указанный
                    номер телефона и вернет token в HTTP заголовке
                    Authorization. Его необходимо сохранить для следующего
                    вызова (пример: Authorization=Bearer eyJhbGciOiJub25lxxxx).
                  </p>

                  <li>
                    <b>Валидация СМС-кода</b>.
                  </li>
                  <p>
                    Отправляется POST запрос с пустым содержимым на любой 2fa
                    endpoint операции сервиса с добавлением двух HTTP
                    заголовков: HTTP_X_OTP и Authorization.
                  </p>
                  <p>
                    В заголовке HTTP_X_OTP указывается код из СМС (пример,
                    HTTP_X_OTP=1234), а в заголовке Authorization - token,
                    полученный на первом шаге (пример: Authorization=Bearer
                    eyJhbGciOiJub25lxxxx).
                  </p>
                  <p>
                    Если был указан корректный код и token, в ответ вернется
                    HTTP-код 200 OK, в противном случае - 403 Forbidden.
                  </p>
                  <p>
                    При получении успешного ответа (HTTP код 200 OK) следует
                    сохранить содержимое заголовка Authorization из ответа и
                    передавать его при дальнейших вызовах любых API-методов в
                    HTTP заголовке (пример: Authorization=Bearer
                    uBXjbZci9iJub25lyyyy).
                  </p>
                </ol>
              </div>
            </div>

            <div className="row" style={{ marginBottom: '120px' }}>
              <div className="col-1"></div>
              <div className="col-10">
                <h2>Как внедрить форму на страницу партнера</h2>

                <Tabs defaultActiveKey="profile" id="from-type-tab">
                  <Tab eventKey="1" title="Для песочницы">
                    <Card bg="light" className="form-instruction">
                      <Card.Header>{form.title} (песочница)</Card.Header>
                      <Card.Body>
                        <p className="form-instruction-description">
                          {' '}
                          <a href={url}>&lt; Вернуться к списку форм</a>
                        </p>
                        <Card.Title>
                          Инструкция по внедрению этой формы на ваш сайт:
                        </Card.Title>

                        <p className="form-instruction-description">
                          На html-странице необходимо выполнить три простых
                          шага:
                        </p>

                        <ol>
                          <li>
                            Подключить .css файл перед закрывающим тегом
                            &lt;/head>
                            <p>
                              &lt;link rel="stylesheet" href="{form.cssUrl}
                              ">&lt;/link>
                            </p>
                          </li>

                          <li>
                            В то место на странице, где вы хотите отобразить
                            форму, добавить следующий div-элемент.
                            <p>{divString}</p>
                          </li>

                          <li>
                            Подключить .js файл перед закрывающим тегом
                            &lt;/body>
                            <p>
                              &lt;script async src="{form.jsUrl}"
                              type="text/javascript">&lt;/script>
                            </p>
                          </li>
                        </ol>
                      </Card.Body>
                    </Card>
                  </Tab>
                  <Tab eventKey="2" title="Для продуктивной эксплуатации">
                    <Card bg="light" className="form-instruction">
                      <Card.Header>
                        {form.title} (продуктивная версия)
                      </Card.Header>
                      <Card.Body>
                        <p className="form-instruction-description">
                          {' '}
                          <a href={url}>&lt; Вернуться к списку форм</a>
                        </p>
                        <Card.Title>
                          Инструкция по внедрению этой формы на ваш сайт:
                        </Card.Title>

                        <p className="form-instruction-description">
                          На html-странице необходимо выполнить три простых
                          шага:
                        </p>

                        <ol>
                          <li>
                            Подключить .css файл перед закрывающим тегом
                            &lt;/head>
                            <p>
                              &lt;link rel="stylesheet" href="
                              {form.cssUrl
                                .split('.css')
                                .join('_partner.css')
                                .split('-sandbox')
                                .join('')}
                              ">&lt;/link>
                            </p>
                          </li>

                          <li>
                            В то место на странице, где вы хотите отобразить
                            форму, добавить следующий div-элемент.
                            <p>
                              {divString
                                .split('></div>')
                                .join(
                                  ' data-partner-id="{partnerId}" data-dadata-token="{daDataToken}"></div>'
                                )
                                .split('-sandbox')
                                .join('')}
                            </p>
                          </li>

                          <li>
                            Подключить .js файл перед закрывающим тегом
                            &lt;/body>
                            <p>
                              &lt;script async src="
                              {form.jsUrl
                                .split('.js')
                                .join('_partner.js')
                                .split('-sandbox')
                                .join('')}
                              " type="text/javascript">&lt;/script>
                            </p>
                          </li>
                        </ol>
                        <Card.Title>Параметры:</Card.Title>

                        <p>
                          <b>partnerId</b> - ваш идентификатор партнера,
                          выданный при подключении к Open API
                          <br />
                          <b>daDataToken</b> - ваш токен сервиса DaData
                          (подключается партнером отдельно)
                        </p>
                      </Card.Body>
                    </Card>
                  </Tab>
                </Tabs>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    api: state.api.structure,
    forms: state.api.forms,
    products: state.api.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getApi: (id, callback) => {
      dispatch(getApiStructureAction(id, callback));
    },
    getForms: (id, callback) => {
      dispatch(getFormsStructureAction(id, callback));
    },
    getProducts: (id, callback) => {
      dispatch(getProductsStructureAction(callback));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApiFormPreview)
);

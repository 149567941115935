import './ApiSwagger.css';
import 'swagger-ui-react/swagger-ui.css';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SwaggerUI from 'swagger-ui-react';

import {
  getApiStandardAction,
  getApiStructureAction,
} from '../../../store/actions/apiActions';
import NavigationBar from './../../../components/navigation-bar/NavigationBar';
import ApiHeader from './../api-header/ApiHeader';
import ApiNavigationBar from './../api-navigation-bar/ApiNavigationBar';
import ConfluenceContent from './../confluence-content/ConfluenceContent';

function ApiSwagger({ api, getApi, content, getContent, match }) {
  // "/api/:apiId/CHECK:checkNum.BUC:bucId.:apiPart"
  const apiId = match.params.apiId;
  let id = `STD${match.params.stdNum}.${match.params.stdPostfix}.${match.params.apiPart}`;

  //const [sandboxOpened, setSandboxOpened] = useState(localStorage.getItem("sandbox-window") || false );
  const [sandboxOpened, setSandboxOpened] = useState(false);

  function openSandbox(opened) {
    setSandboxOpened(opened);
  }

  useEffect(() => {
    getApi(apiId, (api) => {
      if (api) getContent(id, api);
    });
  }, [id]);

  if (
    api === null ||
    api === undefined ||
    content === null ||
    content === undefined
  )
    return null;

  //let procInfo = api.clearName(proc.name);

  // console.error(content.endpoint);

  return (
    <React.Fragment>
      <NavigationBar />
      <div className="api-swagger">
        <div className="container-fluid">
          <ApiHeader name={api.name} />
          <ApiNavigationBar api={api} />

          <div className="api-swagger-content">
            <div className="row">
              <div className="col-1"></div>
              <div className="col-10">
                {api.id === 'A13' && (
                  <SwaggerUI
                    url={`${process.env.REACT_APP_SERVER_ROOT}/resources/swagger-api13.json`}
                  />
                )}

                {api.id === 'A18' && (
                  <SwaggerUI
                    url={`${process.env.REACT_APP_SERVER_ROOT}${process.env.REACT_APP_A18_SWAGGER}`}
                  />
                )}

                {api.id === 'A24' && (
                  <SwaggerUI
                    url={`${process.env.REACT_APP_SERVER_ROOT}/storage/A24/SWAGGER.BUC1.A24/attachments/SWAGGER.GET.BUC1.A24.json`}
                  />
                )}

                {api.id === 'A26' && (
                  <SwaggerUI
                    url={`https://api.raiffeisen.ru/openapi-026-snd/credit-mortgage-leads/app/swagger`}
                  />
                )}
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    api: state.api.structure,
    content: state.api ? state.api.standard : '',
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getApi: (id, callback) => {
      dispatch(getApiStructureAction(id, callback));
    },
    getContent: (id, api, callback) => {
      dispatch(getApiStandardAction(id, api, callback));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApiSwagger)
);

import React from 'react';
import NavigationBar from './../../components/navigation-bar/NavigationBar'

import ApiList from './../../components/api-list/ApiList'


import './ProductView.css'


function ProductView() {
    return (
        <React.Fragment>
        
                <NavigationBar />
                <ApiList />
            
        </React.Fragment>
    )
}

export default ProductView;
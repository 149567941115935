// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/fonts.scss';
import './styles/custom.scss';
import './styles/modalform.css';

import { Provider } from 'react-redux';
import store from './store/index';

import { Route, Switch, HashRouter } from 'react-router-dom';
//import history from './routes/history'
import { routes } from './routes/index';

import Footer from './components/footer/Footer';
import ContactLightHighlights from './components/contact-light-highlights/ContactLightHighlights';

import * as serviceWorker from './serviceWorker';
window.Buffer = window.Buffer || require('buffer').Buffer;

const NoMatch = ({ location }) => (
  <div>
    <h3>
      No match for <code>{location.pathname}</code>
    </h3>
  </div>
);

ReactDOM.render(
  <Provider store={store}>
    <div className="portal-content">
      <HashRouter>
        <Switch>
          {routes.map((prop, key) => (
            <Route
              path={prop.path}
              key={key}
              component={prop.component}
            ></Route>
          ))}
          <Route component={NoMatch} />
        </Switch>
      </HashRouter>
      {/* <ContactLightHighlights /> */}
    </div>

    <Footer />
    {/* <div className="sandbox-modal">
            <h1>Песочница</h1>
            <p>sdfkjshd fjaskldfj alskdjf kajsdhf kajshdf kjahsd kfhas kdfjhak sjdhfk 777</p>
        </div> */}
  </Provider>,
  document.getElementById('oapiportal')
);
serviceWorker.unregister();

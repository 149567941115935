import React, {useRef, useEffect} from 'react';
import { withRouter } from 'react-router-dom'

import './GetStarted.css'

import step1 from './../../assets/img/step1.png'
import step2 from './../../assets/img/step2.png'
import step3 from './../../assets/img/step3.png'

function GetStarted({
    match
}) {

    const element = useRef(null);

    useEffect(() => {
        if (element && element.current && window.location.href.indexOf("get-started") >= 0) {
            element.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            var parts = window.location.href.split('#');
            if (parts.length >= 2) {
                window.location = [parts[0], parts[1]].join('#');
            }
        }
    }, [window.location.href]);

    return (
      <div className="get-started" ref={element}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-1"></div>
            <div className="col-10">
              <h2>
                Как подключиться{' '}
                <a name="get-started" href="">
                  &nbsp;
                </a>
              </h2>
            </div>

            <div className="col-1"></div>
          </div>

          <div className="row">
            <div className="col-1"></div>
            <div className="col-10">
              <p className="lead">
                Выполните три шага для подключения к инструментам разработчика,
                чтобы начать зарабатывать на&nbsp;своих инновационных
                приложениях. После&nbsp;заключения договора с&nbsp;Банком вы
                можете использовать интеграционные сервисы Райффайзенбанка
                в&nbsp;продуктивной среде. Условия&nbsp;договора оговариваются
                с&nbsp;каждым партнером индивидуально.
              </p>
            </div>
            <div className="col-1"></div>
          </div>

          <div className="row">
            <div className="col-1"></div>
            <div className="col-10">
              <div className="row steps">
                <div className="col-lg-4 step">
                  <img alt="" src={step1} className="img-fluid" />
                  <h3>Оставьте заявку на подключение</h3>
                  <p className="details">
                    Оставьте заявку на подключение&nbsp;портале разработчика
                    и&nbsp;получите идентификатор партнера.
                  </p>
                </div>
                <div className="col-lg-4 step">
                  <img alt="" src={step2} className="img-fluid" />
                  <h3>
                    Изучите документацию и&nbsp;протестируйте приложение
                    в&nbsp;песочнице
                  </h3>
                  <p className="details">
                    Посмотрите подробную документацию к&nbsp;API-сервисам
                    и&nbsp;визуальным формам. Протестируйте интеграцию своего
                    приложения с&nbsp;Банком в&nbsp;интерактивной песочнице.
                  </p>
                </div>
                <div className="col-lg-4 step">
                  <img alt="" src={step3} className="img-fluid" />
                  <h3>
                    Заключите договор c&nbsp;Банком и&nbsp;получите
                    SSL&nbsp;сертификат
                  </h3>

                  <p className="details">
                    Если вы планируете вызывать REST API Банка, вам
                    понадобится&nbsp;{' '}
                    <a href="https://api.raiffeisen.ru/#/#getting-started">
                      получить SSL-сертификат
                    </a>
                    . В случае использования визуальных форм сертификат
                    не&nbsp;требуется.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-1"></div>
          </div>

          <div className="row">
            <div className="col-1"></div>
            <div className="col-10">
              {/* <p className="call-to-action">
                            <a href={`${process.env.REACT_APP_WEB_ROOT}/products/`} className="btn btn-primary cta-button">Оставить заявку на&nbsp;подключение</a>
                        </p> */}
            </div>
            <div className="col-1"></div>
          </div>
        </div>
      </div>
    );
}

export default withRouter(GetStarted);
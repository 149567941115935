import {
    PRODUCTS_GET_STRUCTURE_FULFILLED,
    FORMS_GET_STRUCTURE_FULFILLED,
    API_GET_STRUCTURE_FULFILLED,
    API_GET_DESCRIPTION_FULFILLED,
    API_GET_BUC_FULFILLED,
    API_GET_OPERATION_FULFILLED,
    API_GET_OPERATION_MESSAGE_FULFILLED,
    API_GET_DICTIONARY_FULFILLED,
    API_GET_PROCEDURE_FULFILLED,
    API_GET_SAMPLE_FULFILLED,
    API_GET_STANDARD_FULFILLED,
    API_CLEAR_SAMPLE_FULFILLED
} from './../actions/types'

const initialState = {
    products: null,
    forms: null,
    structure: null,
    description: '',
    buc: '',
    operation: '',
    operationMessage: '',
    dictionary: '',
    procedure: '',
    standard: '',
    sample: {
        id: null
    }
}

export default function (state = initialState, action) {
    switch(action.type) {
        case FORMS_GET_STRUCTURE_FULFILLED:
            return {
                ...state,
                forms: action.payload
            };
        case PRODUCTS_GET_STRUCTURE_FULFILLED:
            return {
                ...state,
                products: action.payload
            };
        case API_GET_STRUCTURE_FULFILLED:
            return {
                ...state,
                structure: action.payload
            };
        case API_GET_DESCRIPTION_FULFILLED:
            return {
                ...state,
                description: action.payload
            };
        case API_GET_BUC_FULFILLED:
            return {
                ...state,
                buc: action.payload
            };
        case API_GET_OPERATION_FULFILLED:
            return {
                ...state,
                operation: action.payload
            };
        
        case API_GET_STANDARD_FULFILLED:
                return {
                    ...state,
                    standard: action.payload
                };
        case API_GET_OPERATION_MESSAGE_FULFILLED:
            return {
                ...state,
                operationMessage: action.payload
            };
        case API_GET_DICTIONARY_FULFILLED:
            return {
                ...state,
                dictionary: action.payload
            };
        case API_GET_PROCEDURE_FULFILLED:
            return {
                ...state,
                procedure: action.payload
            };
        case API_GET_SAMPLE_FULFILLED:
            return {
                ...state,
                sample: action.payload
            };
        case API_CLEAR_SAMPLE_FULFILLED:
            
            return {
                ...state,
                sample: initialState.sample
            };
        default: return state;
    }
}
import WelcomeView from './../views/welcome-view/WelcomeView'
import ApiDescription from '../components/api/api-description/ApiDescription';
import ApiBuc from '../components/api/api-buc/ApiBuc';
import ApiStandard from '../components/api/api-standard/ApiStandard';
import ApiOperation from './../components/api/api-operation/ApiOperation'
import ApiDictionary from './../components/api/api-dictionary/ApiDictionary'
import ApiProcedure from '../components/api/api-procedure/ApiProcedure';
import ApiSandbox from '../components/api/api-sandbox/ApiSandbox';
import ApiForms from '../components/api/api-forms/ApiForms';
import ProductView from '../views/product-view/ProductView';
import ProductsView from '../views/products-view/ProductsView';
import FormsView from '../views/forms-view/FormsView';
import ApiFormPreview from '../components/api/api-forms/api-form-preview/ApiFormPreview'
import ApiSwagger from '../components/api/api-swagger/ApiSwagger';

export const SWAGGER_PATH = `/swagger/:apiId`;
export const API_DESCRIPTION_PATH = `/api/:apiId`;
export const API_BUC_PATH = `/api/:apiId/BUC:bucId`;
export const API_OPERATION_PATH = `/api/:apiId/BUC:bucId/:operId`;
export const API_MESSAGE_PATH = `/api/:apiId/BUC:bucId/:operId/:messageId`;
export const API_DICTIONARY_PATH = `/api/:apiId/:dictPart.DICT.:apiPart`;
export const API_PROCEDURE_PATH = `/api/:apiId/CHEC:checkNum.BUC:bucId.:apiPart`;
export const API_STANDARD_PATH = `/api/:apiId/STD:stdNum.:stdPostfix.:apiPart`;
export const API_SANDBOX_PATH = `/api/:apiId/sandbox`;
export const API_FORMS_PATH = `/api/:apiId/forms`;
export const API_FORM_PREVIEW_PATH = `/api/:apiId/:formPart.FORM.:apiPart`;
export const API_PRODUCT_PATH = `/products/:productId/`;
export const API_PRODUCTS_PATH = `/products/`;
export const API_FORMS_LIST_PATH = `/forms/`;

export const routes = [

    {
        path: SWAGGER_PATH, name: "Swagger", component: ApiSwagger
    },
    {
        path: API_PRODUCT_PATH, name: "Product", component: ProductView
    },
    {
        path: API_PRODUCTS_PATH, name: "Products", component: ProductsView
    },
    {
        path: API_FORM_PREVIEW_PATH, name: "FormInstance", component: ApiFormPreview
    },
    {
        path: API_FORMS_PATH, name: "Forms", component: ApiForms
    },
    {
        path: API_SANDBOX_PATH, name: "Sandbox", component: ApiSandbox
    },
    {
        path: API_STANDARD_PATH, name: "Standard", component: ApiStandard
    },
    {
        path: API_PROCEDURE_PATH, name: "Procedure", component: ApiProcedure
    },
    {
        path: API_DICTIONARY_PATH, name: "Dictionary", component: ApiDictionary
    },
    {
        path: API_MESSAGE_PATH, name: "Message", component: ApiOperation
    },
    {
        path: API_OPERATION_PATH, name: "Operation", component: ApiOperation
    },
    {
        path: API_BUC_PATH, name: "Buc", component: ApiBuc
    },
    {
        path: API_DESCRIPTION_PATH, name: "Api", component: ApiDescription
    },
    {
        path: API_FORMS_LIST_PATH, name: "FormsList", component: FormsView
    },
    {
        path: "/", name: "Home", component: WelcomeView
    }
];

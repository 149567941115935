import React from 'react';
import NavigationBar from './../../components/navigation-bar/NavigationBar'

import ApiProductHighlights from './../../components/api-product-highlights/ApiProductHighlights'


import './ProductsView.css'


function ProductsView() {
    return (
        <React.Fragment>
        
                <NavigationBar />
                <ApiProductHighlights />
            
        </React.Fragment>
    )
}

export default ProductsView;